import {Grid} from "@mui/material"
import React from "react"

const RaterMetricas = () => {
    return (<Grid>
        </Grid>

    )
}

export default RaterMetricas