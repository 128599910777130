export const formatDecimalCase = (value, size) => {
    if (value && typeof value !== "number" && value.includes(',')) value = value.replace(',', '.')

    const fixedValud = parseFloat(value).toFixed(size)
    if (isNaN(fixedValud)) {
        return null
    }

    return fixedValud
}

export const formatDecimalCaseWithComma = (value, size) => {
   

    const fixedValue = parseFloat(value).toFixed(size)
    console.log(value, fixedValue)
    if (isNaN(fixedValue)) {
        return null
    }

    return fixedValue.replace('.', ',')
}

export const formatDecimalCaseReplaceComma = (value) => {
    if (value === null) {
        return value
    }

    return String(value).replace('.', ',')
}

export const formatDecimalCaseReplaceDot = (value) => {
    if (value === null) {
        return value
    }

    return String(value).replace(',', '.')
}

export const formatStringToFloatDecimalCase = (value, minimum, maximum) => {
    if (value === null || value === undefined) {
        return null
    }

    const floatValue = parseFloat(value)
    return floatValue.toLocaleString('pt-BR', {
        minimumFractionDigits: minimum ? minimum : 2,
        maximumFractionDigits: maximum ? maximum : 2,
    })
}

export const formatToFloatDecimalCase = (value) => {
    if (value === 0) return value

    // Remove todos os pontos, exceto o último, e substitui a vírgula por ponto.
    const formatedValue = value.replace(/\.(?=.*\.)/g, '').replace(',', '.')
    const floatValue = parseFloat(formatedValue)

    if (isNaN(floatValue)) {
        return null
    }

    // Formata para manter os zeros após a vírgula, com duas casas decimais
    return floatValue.toFixed(2)
}

export const changeFormatedValue = (value) => {
    // Converte o valor para string, caso seja um número
    let stringValue = value.toString()

    // Remove quaisquer caracteres que não sejam dígitos ou pontos e vírgulas
    const availableCharacters = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ',', '.']
    const characterArray = [...stringValue].filter(character => availableCharacters.includes(character))

    // Se o valor não tiver apenas caracteres permitidos, retorna o valor original
    if (characterArray.length === 0) return value

    // Remove pontos e vírgulas e converte para um array de caracteres
    const numberArray = characterArray.filter(character => character !== ',' && character !== '.')

    // Determina onde colocar a vírgula (duas últimas casas decimais)
    if (numberArray.length > 2) {
        numberArray.splice(-2, 0, ',')
    }

    // Insere pontos para separar milhares
    for (let i = numberArray.length - 6; i > 0; i -= 3) {
        numberArray.splice(i, 0, '.')
    }

    // Retorna o valor formatado como string
    return numberArray.join('')
}

export const formatarValor = (valor) => {
    if (valor === 0) {
        return valor
    }

    if (typeof valor === 'number') {
        return valor
    }

    // Encontra a posição do último ponto
    const ultimoPontoIndex = valor.lastIndexOf('.')

    if (ultimoPontoIndex === -1) {
        // Se não houver ponto, apenas substitua a vírgula por ponto
        return parseFloat(valor.replace(',', '.'))
    }

    // Remove todos os pontos, exceto o último
    let valorSemPontos = valor.substring(0, ultimoPontoIndex).replace(/\./g, '') + valor.substring(ultimoPontoIndex)

    // Substitui a vírgula por ponto
    let valorFormatado = valorSemPontos.replace(',', '.')

    return parseFloat(valorFormatado)
}

export const removerPontosVirgulaValor = (valor) => {
    console.log(typeof valor)
    if (valor === null || valor === undefined) {
        return 0
    }
    // Remove todos os pontos
    let valorSemPontos = valor.replace(/\./g, '')
    let valorFormatado = valorSemPontos.replace(',', '.')
    return parseFloat(valorFormatado)
}

export const formatNumberToString = (value) => {
    // Converte o valor para número, caso seja string
    const numberValue = parseFloat(value)

    // Verifica se o valor é um número válido e não é NaN (Not a Number)
    if (isNaN(numberValue)) {
        return value
    }

    // Converte o número para string e divide na parte inteira e decimal
    let [integerPart, decimalPart] = numberValue.toFixed(2).split('.')

    // Adiciona os pontos como separador de milhar na parte inteira
    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.')

    // Retorna o número formatado com vírgula e duas casas decimais
    return `${integerPart},${decimalPart}`
}

export const formataNumeroCasadeMilhar = (num) => {
    if (num == undefined || num === null) {
        return null
    }
    // Se o número for menor que 1000, retorna ele sem formatação
    if (num < 1000) {
        return num
    }
    // Se for maior ou igual a 1000, adiciona o separador de milhar com ponto
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
}
