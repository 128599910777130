import { Grid, Box } from "@mui/material"
import React, { useState } from "react"
import LayoutPage from "src/components/page-layout/layout-page"
import SelectValue from "src/components/select-value"
import { parameterEndpoint, parameterTableList } from "./parameters-data"
import BacenTaxaMediaPFParameters from "./parameterTables/bacenTaxaMediaPFParameters"
import BacenTaxaMediaPJParameters from "./parameterTables/bacenTaxaMediaPJParameters"
import BacenInadimplenciaParameters from "./parameterTables/bacenInadimplenciaParameters"
import BacenPDDAdicionalParameters from "./parameterTables/bacenPDDAdicionalParameters"
import BacenSRCParameters from "./parameterTables/bacenSRCParameters"
import BacenTaxasJurosParameters from "./parameterTables/bacenTaxasJurosParameters"
import RazaoRatingParameters from "./parameterTables/razaoRatingParameters"
import BVModeloParameters from "./parameterTables/bvModeloParameters"
import PDRatingParameters from "./parameterTables/pdRatingParameters"
import TipoRecebivelParameters from "./parameterTables/tipoRecebivelParameters"
import TipoGarantiaParameters from "./parameterTables/tipoGarantiaParameters"
import ScoreRatingParameters from "./parameterTables/scoreRatingParameters"
import ScoreBureauRatingParameters from "./parameterTables/scoreBureauRatingParameters"
import PDSpreadEadRecebivelParameters from "./parameterTables/PDSpreadEadRecebivelParameters"
import PDEmpresasParameters from "./parameterTables/pdEmpresasParameters"
import AlertMessage from "src/components/alert-message"

const ParametersPage = () => {

    const [tableSelected, setTableSelected] = useState("")
    const [loading, setLoading] = useState(false)
    const [endpointSelected, setEndpointSelected] = useState("")

    const setTable = (value) => {

        setTableSelected(value)

        if (value === "") return
        const { endpoint } = parameterEndpoint.filter((item) => item.value === value)[0]
        setEndpointSelected(endpoint)
    }

    const [alertMessageState, setAlertMessageState] = useState({
        open: false,
        message: "",
        type: ""
    })

    const openAlert = (message, type) => {
        setAlertMessageState({
            open: true,
            message: message,
            type: type
        })

        setTimeout(() => {
            setAlertMessageState({ ...alertMessageState, open: false })
        }, 5000)
    }

return <LayoutPage title="Parâmetros" loading={loading}>
        <div>
            <AlertMessage open={alertMessageState.open} message={alertMessageState.message} type={alertMessageState.type} close={() => {}} />
            <Box sx={{ mx: 2, width: 'auto' }}>
                <Grid container alignItems="flex-start" justifyContent="flex-start" spacing={3}>
                    <Grid item sm={12} md={6} xl={4} xs={12}>
                        <SelectValue
                            label={"Tabela"}
                            value={tableSelected}
                            id="select-app"
                            changeValue={setTable}
                            options={parameterTableList} />
                    </Grid>
                    <Grid item sm={12} md={12} xl={12} xs={12}>
                        {(() => {
                            const componentMap = {
                                [parameterTableList[0].value]: <BacenInadimplenciaParameters endpoint={endpointSelected} loading={setLoading} openAlert={openAlert}/>,
                                [parameterTableList[1].value]: <BacenPDDAdicionalParameters endpoint={endpointSelected} loading={setLoading} openAlert={openAlert}/>,
                                [parameterTableList[2].value]: <BacenSRCParameters endpoint={endpointSelected} loading={setLoading} openAlert={openAlert}/>,
                                [parameterTableList[3].value]: <BacenTaxaMediaPFParameters endpoint={endpointSelected} loading={setLoading} openAlert={openAlert}/>,
                                [parameterTableList[4].value]: <BacenTaxaMediaPJParameters endpoint={endpointSelected} loading={setLoading} openAlert={openAlert}/>,
                                [parameterTableList[5].value]: <BacenTaxasJurosParameters endpoint={endpointSelected} loading={setLoading} openAlert={openAlert}/>,
                                [parameterTableList[6].value]: <BVModeloParameters endpoint={endpointSelected} loading={setLoading} openAlert={openAlert}/>,
                                [parameterTableList[7].value]: <PDEmpresasParameters endpoint={endpointSelected} loading={setLoading} openAlert={openAlert}/>,
                                [parameterTableList[8].value]: <PDRatingParameters endpoint={endpointSelected} loading={setLoading} openAlert={openAlert}/>,
                                [parameterTableList[9].value]: <PDSpreadEadRecebivelParameters endpoint={endpointSelected} loading={setLoading} openAlert={openAlert}/>,
                                [parameterTableList[10].value]: <RazaoRatingParameters endpoint={endpointSelected} loading={setLoading} />,
                                [parameterTableList[11].value]: <ScoreBureauRatingParameters endpoint={endpointSelected} loading={setLoading} openAlert={openAlert}/>,
                                [parameterTableList[12].value]: <ScoreRatingParameters endpoint={endpointSelected} loading={setLoading} openAlert={openAlert}/>,
                                [parameterTableList[13].value]: <TipoGarantiaParameters endpoint={endpointSelected} loading={setLoading} openAlert={openAlert}/>,
                                [parameterTableList[14].value]: <TipoRecebivelParameters endpoint={endpointSelected} loading={setLoading} openAlert={openAlert}/>,
                            }                     
 
                            const selectedComponent = componentMap[tableSelected]

                            return selectedComponent || null
                        })()}
                    </Grid>

                </Grid>
            </Box>


        </div>
    </LayoutPage>
}

export default ParametersPage
