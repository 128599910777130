import { ServiceRequest } from "src/utils/service-request"

class DatalakeService {

    constructor() {
        this.serviceRequest = new ServiceRequest()
    }

    async paginate(endpoint) {
        return await this.serviceRequest.getEndpoint(endpoint)
    }

    async loadEndpoint(endpoint, filter = null) {
        return await this.serviceRequest.get(`${endpoint}?limit=10000&${filter}`)
    }

    async loadPaginationEndpoint(endpoint, filter = null, limit = 25, offset = 0) {
        return await this.serviceRequest.get(`${endpoint}?limit=${limit}&offset=${offset}&${filter}`)
    }

    async createTableEndpoint(endpoint, body) {
        console.log(endpoint, body)
        return await this.serviceRequest.post(`${endpoint}`, body)
    }

    async updateTableEndpoint(endpoint, id, body) {
        return await this.serviceRequest.patch(`${endpoint}/${id}`, body)
    }

    async deleteTableEndpoint(endpoint, id) {
        return await this.serviceRequest.delete(`${endpoint}/${id}`)
    }
}

export default DatalakeService