import { Button, FormControl, InputLabel, MenuItem, Select, Grid, Typography } from "@mui/material"
import React, { useState, useEffect } from "react"
import AlertMessage from "src/components/alert-message"
import DatePickerSelect from "src/components/date/date-picker-select"
import DateTimePickerSelectDate from "src/components/date/date-time-picker-select-date"
import LayoutPage from "src/components/page-layout/layout-page"
import { DATE_FORMAT } from "src/utils/constants"
import { IntegrationService } from "src/services/integration-service"
import dayjs from "dayjs"
import SkeletonTable from "src/components/skeleton-table"
import { DataGrid } from "@mui/x-data-grid"
// import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded'

// import { localeBRDataGridText } from "src/utils/data-grid-local-text";


const ReportDataWorkflowPage = () => {
    const [alertMessageState, setAlertMessageState] = useState({
        open: false,
        message: "",
        type: ""
    })
    
    const [dateSelected, setDateSelected] = useState(dayjs().subtract(7, 'day').set('hour', 19).set('minute', 15).set('second', 0).set('millisecond', 670))
    const [dateSelectedDate, setDateSelectedDate] = useState(dayjs())
    const [loading, setLoading] = useState(false)
    const [name, setName] = useState(null)
    const [loadTable, setLoadTable] = useState(false)
    const [setRoutineList] = useState([])  
    const [tableData, setTableData] = useState([]) 
    const [columns, setColumns] = useState([])
    
    

    const integrationService = new IntegrationService()

    const alterarValor = (event) => {
        const selectedValue = event.target.value
        setName(selectedValue)
    }

    const closeAlert = () => {
        setAlertMessageState({ ...alertMessageState, open: false })
    }

    useEffect(() => {
        loadLastRoutines()
    }, [])

    const extractMetadata = (obj) => {
        const { metadata } = obj
        try {
            const valores = metadata.split(',')
            let data_referencia = valores.find(elemento => elemento.includes("data_referencia"))
            let qtde_dias = valores.find(elemento => elemento.includes("qtde_dias"))

            data_referencia !== undefined ? data_referencia = data_referencia.slice(-11).slice(0, -1) : null
            qtde_dias !== undefined ? qtde_dias = qtde_dias.slice(-3).slice(0, -1) : 0

            return { data_referencia: data_referencia, qtde_dias: qtde_dias }
        } catch (err) {
            return null
        }
    }

    const loadLastRoutines = () => {
        setLoadTable(true)
        integrationService.getRoutinesWorkflowInfo().then((response) => {
            const { body } = response
            const { results } = body
            const rows = results.map((obj, index) => {
                const metadata = extractMetadata(obj)

                return {
                    ...obj,
                    id: index,
                    data_referencia: metadata === null ? null : metadata.data_referencia,
                    qtde_dias: metadata === null ? 90 : metadata.qtde_dias,
                }
            })

            setRoutineList(rows)
            setLoadTable(false)
        }).catch((err) => {
            console.log(err)
            setLoadTable(false)
        })
    }
   
    const onChangeDatePickerDate = (value) => {
        setDateSelected(value)
    }

    const onChangeDatePickerDateTime = (value) => {
        const formattedDate = value.format(DATE_FORMAT)
        setDateSelectedDate(formattedDate)
    }

    const openAlert = (message, type) => {
        setAlertMessageState({
            ...alertMessageState,
            open: true,
            message: message,
            type: type
        })
    }

    const inicialRotina = () => {
        setLoading(true)
    
        const handleResponse = (response) => {
            try {
                const jsonData = JSON.parse(response.text)
                console.log('Dados recebidos da API:', jsonData)
    
                if (jsonData && Object.keys(jsonData).length > 0) {
                    const tableData = Object.keys(jsonData).map((key, index) => ({
                        id: `${key}_${index}_${Date.now()}`, // ID único incluindo timestamp
                        dados: key,
                        qtde: jsonData[key],
                    }))
    
                    console.log('Dados transformados para tabela:', tableData)
    
                    // Configuração das colunas
                    const generatedColumns = [
                        { field: 'dados', headerName: 'Dados', width: 200 },
                        { field: 'qtde', headerName: 'Qtde', width: 200 },
                    ]
    
                    // Atualizar estados para renderizar no DataGrid
                    setColumns(generatedColumns)
                    setTableData(tableData)
                } else {
                    openAlert('Nenhum dado disponível para exibir.', 'warning')
                }
            } catch (error) {
                console.error('Erro ao processar dados recebidos:', error)
                openAlert('Erro ao processar dados recebidos.', 'error')
            } finally {
                setLoading(false)
            }
        }
    
        const handleError = (error) => {
            console.error('Erro ao buscar dados:', error)
            openAlert('Erro ao buscar dados.', 'error')
            setLoading(false)
        }
    
        // Verificar se o name é "CVMCadastro"
        if (name === "CVMCadastro") {
            integrationService
                .getReportData(name)
                .then(handleResponse)
                .catch(handleError)
        } else {
            integrationService
                .getReportDataInfo(name, dateSelectedDate)
                .then(handleResponse)
                .catch(handleError)
        }
    }
    
    
    // useEffect(() => {
    //     inicialRotina();
    // }, []);
    
    
    return (
        <LayoutPage title="Rotinas" loading={loading}>
            <AlertMessage open={alertMessageState.open} message={alertMessageState.message} type={alertMessageState.type} close={closeAlert} />

            <Grid container alignItems="flex-start" justifyContent="flex-start" spacing={3}>
                <Grid item sm={12} md={6} xl={4} xs={12}>
                    Escolha uma rotina.
                </Grid>
            </Grid>
            <br />
            <Grid container alignItems="center" justifyContent="flex-start" spacing={3}>
                <Grid item xs={12} md={3}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Rotinas</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={name}
                            label="Rotinas"
                            onChange={alterarValor}
                        >
                            <MenuItem value="">Escolha uma rotina</MenuItem>
                            <MenuItem value="B3AtivosReg" disabled>B3AtivosReg</MenuItem>
                                <MenuItem value="B3NegAnalitico" disabled>B3NegAnalitico</MenuItem>
                                <MenuItem value="B3NegConsolidado" disabled>B3NegConsolidado</MenuItem>
                                <MenuItem value="bacen_cadastro_workflow">BacenCadastro</MenuItem>
                                <MenuItem value="cvm_fatos_relevantes_workflow">CVMFatosRelevantes</MenuItem>
                                <MenuItem value="EstoqueRegistrado" disabled>EstoqueRegistrado</MenuItem>
                                <MenuItem value="MarketData" disabled>MarketData</MenuItem>
                                <MenuItem value="fincs_marketdata_workflow">MarketData_airflow</MenuItem>
                                <MenuItem value="PreviaInflacao" disabled>Previa de Inflação</MenuItem>
                                <MenuItem value="PreviaInflacaoAnbima" disabled>PreviaInflacaoAnbima</MenuItem>
                                <MenuItem value="RiskFree" disabled>RiskFree</MenuItem>
                                <MenuItem value="TaxasRecebiveisMercado" disabled>TaxasRecebiveisMercado</MenuItem>
                                <MenuItem value="cvm_cadastro_workflow">CVMCadastro</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                 {name === 'MarketData' && (
                    <Grid item xs={12} md={3}>
                        <DateTimePickerSelectDate
                            label="Data de Exibição"
                            onChange={onChangeDatePickerDate}
                            value={dateSelected}
                        />
                    </Grid>
                )} 

                <Grid item xs={12} md={3}>
                    <DatePickerSelect
                        label="Data de Exibição"
                        onChange={onChangeDatePickerDateTime}
                        value={dateSelectedDate}
                    />
                </Grid>

                <Grid item xs={12} md={3}>
                    <Button variant="contained" onClick={inicialRotina}>Consultar</Button>
                </Grid>
            </Grid>

            <br />
            <br />
            <Grid container direction="row" justifyContent="space-between" spacing={2}>
                <Grid item>
                    <Typography variant={"h5"}>Relatório</Typography>
                </Grid>
                  {/* <Grid item>
                    <Button onClick={loadLastRoutines} startIcon={<RefreshRoundedIcon />} variant="contained">Recarregar</Button>
                </Grid>  */}
            </Grid> 

            <Grid container>
                <Grid item xs={12} md={12}>
                    {loadTable ? <SkeletonTable /> : (
                        <div style={{ height: 400, width: '100%' }}>
                            <DataGrid
                                rows={tableData}     
                                columns={columns}
                                getRowId={(row) => row.id}  // Configuração de ID personalizado
                                pageSize={5}
                                rowsPerPageOptions={[5]}
/>
                        </div>
                    )}
                </Grid>
        </Grid>

        </LayoutPage>
    )
}

export default ReportDataWorkflowPage