import {Box,Grid} from "@mui/material"
import React , { useState } from "react"
import LayoutPage from "src/components/page-layout/layout-page"
import SelectValue from "src/components/select-value"
import { parameterRaterEndpoint, parameterRaterTableList } from "./parameter-rater-data"
import ParameterRaterMMCotacoesTable from "./tables/parameter-rater-mm-cotacoes"
import ParameterRaterMMCotacoesAjustadasTable from "./tables/parameter-rater-mm-cotacoes-ajustadas"
import ParameterRaterMMPDTable from "./tables/parameter-rater-mm-pd"
import ParameterRaterMMResultadoEmpresaTable from "./tables/parameter-rater-mm-resultado-empresa"
import ParameterRaterMMResultadoRatingTable from "./tables/parameter-rater-mm-resultado-rating"
import ParameterRaterMMTickerCNPJTable from "./tables/parameter-rater-mm-ticker-cnpj"
import ParameterRaterMMVolatilidadeTickerTable from "./tables/parameter-rater-mm-volatilidade-ticker"
import ParameterRaterUserSetorialTable from "./tables/parameter-rater-user-setorial"
import ParameterRaterUserCadastroEmpresasTable from "./tables/parameter-rater-user-cadastro-empresas"

const RaterConsultadeDados= () => {
    const [tableSelected, setTableSelected] = useState("")
    const [loading, setLoading] = useState(false)
    const [endpointSelected, setEndpointSelected] = useState("")


    const setTable = (value) => {

        setTableSelected(value)

        if (value === "") return
        const { endpoint } = parameterRaterEndpoint.filter((item) => item.value === value)[0]
        setEndpointSelected(endpoint)
    }

    return (<LayoutPage title="Consulta de Dados" loading={loading}>
        <div>
            <Box sx={{ ml: -8, width: 'auto' }}>
                <Grid container alignItems="flex-start" justifyContent="flex-start" spacing={3}>
                    <Grid item sm={12} md={6} xl={4} xs={12}>
                        <SelectValue
                            label={"Tabela"}
                            value={tableSelected}
                            id="select-app"
                            changeValue={setTable}
                            options={parameterRaterTableList} />
                    </Grid>
                    <Grid item sm={12} md={12} xl={12} xs={12}>
                        {(() => {
                            const componentMap = {
                                [parameterRaterTableList[0].value]: <ParameterRaterUserCadastroEmpresasTable endpoint={endpointSelected} loading={setLoading} />,
                                [parameterRaterTableList[1].value]: <ParameterRaterUserSetorialTable endpoint={endpointSelected} loading={setLoading} />,
                                [parameterRaterTableList[2].value]: <ParameterRaterMMCotacoesTable endpoint={endpointSelected} loading={setLoading} />,
                                [parameterRaterTableList[3].value]: <ParameterRaterMMCotacoesAjustadasTable endpoint={endpointSelected} loading={setLoading} />,
                                [parameterRaterTableList[4].value]: <ParameterRaterMMPDTable endpoint={endpointSelected} loading={setLoading} />,
                                [parameterRaterTableList[5].value]: <ParameterRaterMMResultadoEmpresaTable endpoint={endpointSelected} loading={setLoading} />,
                                [parameterRaterTableList[6].value]: <ParameterRaterMMResultadoRatingTable endpoint={endpointSelected} loading={setLoading} />,
                                [parameterRaterTableList[7].value]: <ParameterRaterMMTickerCNPJTable endpoint={endpointSelected} loading={setLoading} />,
                                [parameterRaterTableList[8].value]: <ParameterRaterMMVolatilidadeTickerTable endpoint={endpointSelected} loading={setLoading} />,
                            }                            
 
                            const selectedComponent = componentMap[tableSelected]

                            return selectedComponent || null
                        })()}
                    </Grid>

                </Grid>
            </Box>


        </div>
    </LayoutPage>
    )
}

export default RaterConsultadeDados