import React, { useState } from "react"
import PropTypes from 'prop-types'
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, Paper, TextField } from "@mui/material"
import { DataGrid, GridCellModes, GridToolbarContainer } from "@mui/x-data-grid"
import ConfirmDialog from "src/components/confirm-dialog"
import { localeBRDataGridText } from "src/utils/data-grid-local-text"
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded'
import AlertMessage from "src/components/alert-message"
import AddIcon from "@mui/icons-material/Add"
import DatePickerRangeSelect from "src/components/date/date-picker-range-select"
import { DATE_FORMAT } from "src/utils/constants"
import DatePickerSelect from "src/components/date/date-picker-select"
import ParameterCustomFooter from "./parameter-custom-footer"
import dayjs from "dayjs"
import { replaceEmptyWithNull } from "src/utils/format-json"
import { formatDecimalCaseReplaceComma, formatDecimalCaseReplaceDot } from "src/utils/format-decimal"
import ParameterIntervalField from "./parameter-interval-field"
import RangeValueField from "../range-value-field"

const INITIAL_VALUE_FILTER = "__exact"
const INITIAL_VALUE = ""
const FILTER_NULL = "null"
const FILTER_NOT_NULL = "!null"
const FILTER_RANGE = "__range"

const intervalList = [
    { value: INITIAL_VALUE_FILTER, description: "é igual" },
    { value: FILTER_RANGE, description: "está entre" },
    { value: "__lte", description: "menor ou igual" },
    { value: "__gte", description: "maior ou igual" },
    { value: "__lt", description: "menor que" },
    { value: "__gt", description: "maior que" },
    { value: FILTER_NULL, description: "é nulo" },
    { value: FILTER_NOT_NULL, description: "não é nulo" },
]

const typeFilterList = [
    { value: INITIAL_VALUE_FILTER, description: "contém" },
    { value: FILTER_NULL, description: "é nulo" },
    { value: FILTER_NOT_NULL, description: "não é nulo" },
]


const PAGE_DEFAULT_SIZE = 25
const PAGE_DEFAULT_INIT = 0

const ParameterPaginationTable = (props) => {
    const [cellModesModel, setCellModesModel] = useState({})
    const [rowModesModel, setRowModesModel] = useState({})
    const [dialogDelete, setDialogDelete] = useState(false)
    const [rowDelete, setRowDelete] = useState(null)
    const [filterDialogOpen, setFilterDialogOpen] = useState(false)

    const [filterField, setFilterField] = useState(INITIAL_VALUE)
    const [filterFieldNull, setFilterFieldNull] = useState(INITIAL_VALUE)
    const [filterType, setFilterType] = useState(INITIAL_VALUE)
    const [filterValue, setFilterValue] = useState(INITIAL_VALUE)
    const [filterList, setFilterList] = useState([])
    const [filterRange, setFilterRange] = useState([])
    const [filterInterval, setFilterInterval] = useState(INITIAL_VALUE_FILTER)

    const [pagination, setPagination] = useState({ init: PAGE_DEFAULT_INIT, end: PAGE_DEFAULT_SIZE })
    const [alertMessageState, setAlertMessageState] = React.useState({
        open: false,
        message: "",
        type: "info"
    })

    const deleteColumn = {
        field: 'delete',
        headerName: 'Excluir',
        flex: true,
        renderCell: (params) => (
            <Button onClick={() => openDeleteRow(params)}>
                <DeleteRoundedIcon />
            </Button>
        ),
        minWidth: 100,
    }

    // adicionando a coluna de removção e a renderizacao para o tipo decimal
    const columns = [...props.columns, deleteColumn].map((column) => {
        if (column.typeComponent === 'number') {
            return {
                ...column,
                renderCell: (params) => formatDecimalCaseReplaceComma(params.value),
            }
        }
        return column
    })

    const openAlert = (message, type) => {
        setAlertMessageState({
            ...alertMessageState,
            open: true,
            message: message,
            type: type
        })
    }

    const deleteRow = () => {
        props.deleteRow(rowDelete).then(() => {
            openAlert("Registro Excluído", "error")
        }).catch(() => {
            openAlert("Erro ao tentar excluir o registro", "error")
        })
    }

    const processRowUpdate = (newRow, oldRow) => {
        const stringifyRow = (row) => JSON.stringify(Object.entries(row).sort())
        const areRowsEqual = stringifyRow(newRow) === stringifyRow(oldRow)
        if (areRowsEqual) return newRow

        let row = replaceEmptyWithNull(newRow)

        props.columns.forEach((column) => {
            if (column.typeComponent === 'number') {
                row[column.field] = formatDecimalCaseReplaceDot(row[column.field])
            }
        })

        props.updateRow(row).then(() => {
            openAlert("Atualizado com sucesso", "success")
        }).catch((err) => {
            try {
                const json = JSON.stringify(err)
                const { response } = JSON.parse(json)
                const { body } = response
                openAlert(`Erro ao tentar atualizar - ${JSON.stringify(body)}`, "error")

            } catch (error) {
                openAlert("Erro ao tentar atualizar", "error")
            }
        })

        return row
    }

    const onProcessRowUpdateError = (err) => {
        console.debug(err)
        openAlert("Erro ao tentar atualizar", "error")
    }

    const openDeleteRow = (params) => {
        const { row } = params
        setRowDelete(row)
        setDialogDelete(true)
    }

    const toolbarComponent = () => {
        const { createRow } = props

        if (!createRow) return null

        const createNewRow = () => {
            createRow()
                .then(() => {
                    setFilterList([])
                    openAlert("Registro Adicionado", "success")
                }).catch((err) => {
                    try {
                        const json = JSON.stringify(err)
                        const { response } = JSON.parse(json)
                        const { body } = response
                        openAlert(`Erro ao adicionar o registro - ${JSON.stringify(body)}`, "error")

                    } catch (error) {
                        openAlert("Erro ao adicionar o registro", "error")
                    }
                })
        }

        return (
            <GridToolbarContainer>
                <Button color="primary" startIcon={<AddIcon />} onClick={() => createNewRow()}>
                    Adicionar Novo
                </Button>
                {props.customFilter ?
                    <Button color="primary" startIcon={<FilterAltRoundedIcon />} onClick={() => openFilterDialog()}>
                        Filtros
                    </Button>
                    : null}
            </GridToolbarContainer>
        )
    }

    const footerComponent = () => {
        return (
            <ParameterCustomFooter total={props.pagination.count}
                onChagePage={onChangePage}
                next={props.pagination.next}
                init={pagination.init}
                end={pagination.end}
                previous={props.pagination.previous} />
        )
    }

    const onChangePage = (endpoint, next) => {
        const pageSize = PAGE_DEFAULT_SIZE
        const { init, end } = pagination

        let newInit, newEnd

        if (next) {
            newInit = init + pageSize
            newEnd = end + pageSize
        } else {
            newInit = Math.max(0, init - pageSize)
            newEnd = Math.max(pageSize, end - pageSize)
        }

        setPagination({ init: newInit, end: newEnd })
        props.onChangePage(endpoint)
    }

    const handleCellEditStop = (
        params,
        event
    ) => {
        const { id, field } = params

        event.defaultMuiPrevented = true
        setCellModesModel({
            ...cellModesModel,
            [id]: { ...cellModesModel[id], [field]: { mode: GridCellModes.View } },
        })
    }

    const openFilterDialog = () => {
        setFilterField(INITIAL_VALUE)
        setFilterValue(INITIAL_VALUE)
        setFilterInterval(INITIAL_VALUE_FILTER)
        setFilterFieldNull(INITIAL_VALUE)
        setFilterDialogOpen(true)
    }

    const closeFilterDialog = () => {
        setFilterDialogOpen(false)
    }

    const applyCustomFilter = () => {
        let field = filterField


        if (field === INITIAL_VALUE) {
            openAlert("Selecione um campo", "warning")
            return
        }

        if (filterInterval === FILTER_RANGE) {
            closeFilterDialog()
            const filters = [...filterList, ...filterRange]
            setFilterList(filters)
            return props.filterFunction(filters)
        }

        if (filterInterval === FILTER_NULL || filterInterval === FILTER_NOT_NULL) {
            field = filterFieldNull
        }

        closeFilterDialog()
        const excludedValues = [INITIAL_VALUE_FILTER, FILTER_NOT_NULL, FILTER_NULL]
        const fieldWithInterval = excludedValues.includes(filterInterval) ? field : `${field}${filterInterval}`
        const filters = [...filterList.filter(item => item.field !== fieldWithInterval), { field: fieldWithInterval, value: filterValue }]

        setFilterList(filters)
        return props.filterFunction(filters, PAGE_DEFAULT_SIZE, PAGE_DEFAULT_INIT)
    }

    const removeFilter = (row) => {
        const removed = filterList.filter((item) => item.field !== row.filter && item.value !== row.value)
        setFilterList(removed)

        return props.filterFunction(removed, PAGE_DEFAULT_SIZE, PAGE_DEFAULT_INIT)
    }

    const changeInputInterval = (value) => {
        setFilterValue(INITIAL_VALUE)
        setFilterInterval(value)

        if (value === FILTER_NULL) {
            setFilterFieldNull(`${filterField}__isnull`)
            setFilterValue(true)
        }

        if (value === FILTER_NOT_NULL) {
            setFilterFieldNull(`${filterField}__isnull`)
            setFilterValue(false)
        }
    }

    const changeInput = (e) => {
        const value = e.target.value

        setFilterField(value)
        const column = columns.filter((item) => item.field === value)[0]
        setFilterType(column.typeComponent)

        if (column.typeComponent === 'date') {
            setFilterValue(dayjs().format(DATE_FORMAT))
            return
        }

        setFilterValue(INITIAL_VALUE)
    }

    const changeRangeDate = (startDate, endDate) => {

        if (filterField === INITIAL_VALUE) {
            openAlert("Selecione qual campo deseja filtrar", "warning")
            return
        }

        const init = startDate.format(DATE_FORMAT)
        const end = endDate.format(DATE_FORMAT)

        const startField = { field: `${filterField}__gte`, value: init }
        const endField = { field: `${filterField}__lte`, value: end }

        const newFilters = [startField, endField]
        setFilterRange(newFilters)
    }

    const changeRangeNumber = (init, end) => {

        if (filterField === INITIAL_VALUE) {
            openAlert("Selecione qual campo deseja filtrar", "warning")
            return
        }
        console.log(init, end)
        const startField = { field: `${filterField}__gte`, value: init }
        const endField = { field: `${filterField}__lte`, value: end }

        const newFilters = [startField, endField]
        setFilterRange(newFilters)
    }

    return (
        <div>
            <AlertMessage open={alertMessageState.open} message={alertMessageState.message} type={alertMessageState.type} close={() => setAlertMessageState({ ...alertMessageState, open: false })} />

            <Grid container direction={"row"} alignContent={"center"} justifyContent={"flex-end"} spacing={2}>
                {filterList.map((row, index) =>
                    <Grid item key={index}>
                        <Chip color={"primary"} variant="outlined" label={`${row.field}=${row.value}`} onDelete={() => { removeFilter(row) }} />
                    </Grid>
                )}
            </Grid>
            <br />

            <Paper>
                <DataGrid
                    autoHeight
                    rows={props.data}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: PAGE_DEFAULT_SIZE,
                            },
                        },
                    }}
                    disableRowSelectionOnClick
                    cellModesModel={cellModesModel}
                    sortingaOrder={['asc', 'desc']}
                    localeText={localeBRDataGridText}

                    onCellEditStop={handleCellEditStop}
                    onCellModesModelChange={(model) => setCellModesModel(model)}

                    rowModesModel={rowModesModel}
                    processRowUpdate={processRowUpdate}
                    onProcessRowUpdateError={onProcessRowUpdateError}
                    handleProcessRowUpdateError={onProcessRowUpdateError}

                    slots={{
                        toolbar: (props.disableToolbar ? null : toolbarComponent),
                        footer: footerComponent
                    }}
                    slotProps={{
                        toolbar: {
                            setData: props.setData,
                            setRowModesModel: setRowModesModel
                        }
                    }}
                />
            </Paper>

            <ConfirmDialog
                open={dialogDelete}
                title={"Deseja remover esse registro?"}
                onConfirm={() => deleteRow()}
                onClose={() => setDialogDelete(false)}>
                <span>Essa ação não pode ser desfeita.</span>
            </ConfirmDialog>

            <Dialog open={filterDialogOpen} onClose={closeFilterDialog} fullWidth maxWidth="sm">
                <DialogTitle>Filtrar Valores</DialogTitle>
                <br />
                <DialogContent>
                    <Grid container direction={"row"} alignContent={"flex-end"} justifyContent={"flex-end"} spacing={2}>

                        <Grid item xs={12} sm={12} md={12}>
                            <TextField
                                label="Campo"
                                select
                                focused
                                fullWidth
                                value={filterField}
                                onChange={changeInput}
                            >
                                {columns.map((column) =>
                                    column.disableFilter || column.field === 'delete' ? null : <MenuItem key={column.field} value={column.field}>{column.headerName}</MenuItem>
                                )}
                            </TextField>
                        </Grid>

                        {filterType === undefined ?
                            <>
                                <Grid item xs={12} sm={12} md={12}>
                                    <ParameterIntervalField value={filterInterval} onChange={changeInputInterval} filterList={typeFilterList} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    {filterInterval !== FILTER_NULL && filterInterval !== FILTER_NOT_NULL && (
                                        <TextField
                                            label="Valor"
                                            focused
                                            fullWidth
                                            type={"text"}
                                            value={filterValue}
                                            onChange={(e) => setFilterValue(e.target.value)}
                                        />
                                    )}
                                    {filterInterval === FILTER_NULL && null}
                                    {filterInterval === FILTER_NOT_NULL && null}
                                </Grid>
                            </>
                            : null}

                        {filterType === "number" ?
                            <>
                                <Grid item xs={12} sm={12} md={12}>
                                    <ParameterIntervalField value={filterInterval} onChange={changeInputInterval} filterList={intervalList} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    {filterInterval && (
                                        <div>
                                            {filterInterval === FILTER_RANGE && <RangeValueField labelFirst="Valor Inicial" labelSecond="Valor Final" onChange={changeRangeNumber} />}
                                            {filterInterval !== FILTER_RANGE && filterInterval !== FILTER_NULL && filterInterval !== FILTER_NOT_NULL && (
                                                <TextField
                                                    label="Valor"
                                                    focused
                                                    fullWidth
                                                    type={"text"}
                                                    value={filterValue}
                                                    onChange={(e) => {
                                                        const value = e.target.value
                                                        setFilterValue(formatDecimalCaseReplaceDot(value))
                                                    }}
                                                />
                                            )}
                                            {filterInterval === FILTER_NULL && null}
                                            {filterInterval === FILTER_NOT_NULL && null}
                                        </div>
                                    )}
                                </Grid>
                            </>
                            : null}


                        {filterType === "date" ?
                            <>
                                <Grid item xs={12} sm={12} md={12}>
                                    <ParameterIntervalField value={filterInterval} onChange={changeInputInterval} filterList={intervalList} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    {filterInterval && (
                                        <div>
                                            {filterInterval === FILTER_RANGE && <DatePickerRangeSelect onChange={changeRangeDate} />}
                                            {filterInterval !== FILTER_RANGE && filterInterval !== FILTER_NULL && filterInterval !== FILTER_NOT_NULL && (
                                                <DatePickerSelect onChange={(value) => setFilterValue(value.format(DATE_FORMAT))} />
                                            )}
                                            {filterInterval === FILTER_NULL && null}
                                            {filterInterval === FILTER_NOT_NULL && null}
                                        </div>
                                    )}
                                </Grid>
                            </>
                            : null}

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeFilterDialog} color="primary">
                        Cancelar
                    </Button>
                    <Button
                        onClick={applyCustomFilter} color="primary" variant="contained">
                        Aplicar Filtro
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    )
}

ParameterPaginationTable.propTypes = {
    disableToolbar: PropTypes.bool,
    columns: PropTypes.array,
    data: PropTypes.array,
    setData: PropTypes.func,
    createRow: PropTypes.func,
    updateRow: PropTypes.func,
    deleteRow: PropTypes.func,
    customFilter: PropTypes.bool,
    pagination: PropTypes.object,
    onChangePage: PropTypes.func,
    filterFunction: PropTypes.func
}


export default ParameterPaginationTable