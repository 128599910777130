import { BOA_VISTA_HEALTH_PATH } from "src/utils/api-routes"
import { ServiceRequest } from "src/utils/service-request"


class BoaVistaService {
    constructor() {
        this.request = new ServiceRequest()
    }

    async getApiStatus() {
        return this.request.get(`${BOA_VISTA_HEALTH_PATH}`)
    }
}

export default BoaVistaService