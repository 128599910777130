import { DADOS_CARTEIRA_ENTRADA, SIMULADOR_HISTORICO, SIMULADOR_SIMULACAO, SIMULADOR_SUBORDINACAO_SIMULADO, SIMULADOR_SUBORDINACAO } from "src/utils/api-routes"
import { formatarValor, formatNumberToString, removerPontosVirgulaValor } from "src/utils/format-decimal"
import { ServiceRequest } from "src/utils/service-request"
import * as XLSX from 'xlsx-js-style'

class SimuladorService {

    constructor() {
        this.request = new ServiceRequest()
    }

    getHistorico() {
        return this.request.get(SIMULADOR_HISTORICO)
    }

    getHistoricoSimulado(IDSubordinacaoLote) {
        return this.request.get(`${SIMULADOR_SUBORDINACAO_SIMULADO}/lote/${IDSubordinacaoLote}`)
    }

    exportExcel(NomeSimulacao, rows) {
        const results = rows

        const headers = [
            { label: "Nome da Cota", key: "NomeCota", format: false },
            { label: "PL da Cota", key: "PLCota", format: true },
            { label: "Indexador", key: "IndexadorCota", format: false },
            { label: "Taxa Accrual", key: "TaxaAccrualCota", format: true },
            { label: "Duration Cota", key: "DurationCota", format: true },
            { label: "Subordinação", key: "SubordinacaoCota", format: true },
            { label: "Rating", key: "RatingCota", format: false },
            { label: "Taxa MTM", key: "TaxaMTMCota", format: true },
            { label: "Spread", key: "SpreadMTMCota", format: true },
            { label: "PD", key: "PDCota", format: true },
            { label: "LGD", key: "LGDCota", format: true },
            { label: "EAD", key: "EADCota", format: true },
            { label: "Perda Esperada", key: "PerdaEsperadaCota", format: true },
            { label: "Credit VaR", key: "CreditVaRCota", format: true },
            { label: "Credit Tail VaR", key: "CreditTailVarCota", format: true }
        ]

        // Criar uma nova planilha
        const workbook = XLSX.utils.book_new()

        // Formatar os dados no formato JSON para a planilha
        const worksheetData = results.map(item => {
            const rowData = {}
            headers.forEach(header => {
                let value = item[header.key] !== null ? item[header.key] : ''
                rowData[header.label] = value // Usa o label como cabeçalho
            })
            return rowData
        })
        // Criar a planilha com json_to_sheet
        const worksheet = XLSX.utils.json_to_sheet(worksheetData)
        // Calcular a largura de cada coluna com base nos dados
        const colWidths = headers.map(header => ({
            wch: Math.max(header.label.length, ...results.map(item => (item[header.key] ? item[header.key].toString().length : 0))) + 2
        }))
        worksheet['!cols'] = colWidths

        // Estilo para cabeçalhos
        const headerStyle = {
            fill: { fgColor: { rgb: "222851" } },
            font: { color: { rgb: "FFFFFF", sz: 14, bold: true } },
            alignment: { wrapText: true, horizontal: "center", vertical: "center" },
            border: {
                top: { style: 'thin', color: { argb: '000000' } },
                bottom: { style: 'thin', color: { argb: '000000' } },
                left: { style: 'thin', color: { argb: '000000' } },
                right: { style: 'thin', color: { argb: '000000' } }
            },
        }

        // Aplicar estilo aos cabeçalhos
        headers.forEach((header, index) => {
            const cellAddress = XLSX.utils.encode_cell({ r: 0, c: index })
            worksheet[cellAddress].s = headerStyle
        })

        // Função para aplicar estilo às células
        const applyCellStyle = (cellAddress) => {
            if (!worksheet[cellAddress]) worksheet[cellAddress] = {}
            worksheet[cellAddress].s = {
                alignment: {
                    horizontal: 'center',
                    vertical: 'center'
                },
                border: {
                    top: { style: 'thin', color: { argb: '000000' } },
                    bottom: { style: 'thin', color: { argb: '000000' } },
                    left: { style: 'thin', color: { argb: '000000' } },
                    right: { style: 'thin', color: { argb: '000000' } }
                },
            }
        }

        // Centralizar os valores nas células e aplicar estilo
        results.forEach((item, rowIndex) => {
            headers.forEach((header, colIndex) => {
                const cellAddress = XLSX.utils.encode_cell({ r: rowIndex + 1, c: colIndex }) // Adiciona 1 para pular o cabeçalho
                const value = item[header.key] !== null ? item[header.key] : ''

                if (header.format) {
                    worksheet[cellAddress].v = formatNumberToString(value) // Define o valor da célula
                } else {
                    worksheet[cellAddress].v = value // Define o valor da célula
                }

                applyCellStyle(cellAddress)
            })
        })

        // Adicionar a planilha ao workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, NomeSimulacao)

        // Escrever o arquivo XLSX
        XLSX.writeFile(workbook, `${NomeSimulacao}.xlsx`)
    }

    getSelectSimulacao() {
        return this.request.get(`${SIMULADOR_SIMULACAO}`)
    }

    loadLoteSelecionado(loteUUID) {
        return this.request.get(`${SIMULADOR_SIMULACAO}/${loteUUID}`)
    }

    getDadosCarteiraEntrada(uuidCarteiraEntrada) {
        return this.request.get(`${DADOS_CARTEIRA_ENTRADA}/${uuidCarteiraEntrada}`)
    }

    postSimulacao(
        idCarteira,
        nome_carteira,
        data_referencia,
        caixa,
        percentil,
        plCota,
        multiplicador,
        tipo_simulacao,
        quantidade_operacoes,
        banda,
        subordinacoes
    ) {
        let body = {
            uuid: idCarteira,
            data_referencia: data_referencia,
            nome_carteira: nome_carteira,
            percentil: percentil,
            caixa: removerPontosVirgulaValor(caixa),
            // plCotaTotal: removerPontosVirgulaValor(plCota),
            tipo_simulacao: tipo_simulacao,
            quantidade_operacoes: quantidade_operacoes,
            banda: banda,
            dados_carteira_entrada_simulacao: subordinacoes.map((entrada) => ({
                Caixa: removerPontosVirgulaValor(caixa),
                NomeCota: entrada.NomeCota,
                PLCota: formatarValor(entrada.PLCota),
                IndexadorCota: entrada.IndexadorCota,
                TaxaAccrualCota: formatarValor(entrada.TaxaAccrualCota),
                DurationCota: parseInt(entrada.DurationCota),
                OrdemPL: entrada.OrdemPL,

                // DataReferencia: data_referencia,
                // IDCarteiraLote_id: idCarteira,
                // Percentil: percentil,
            })),
        }

        if (tipo_simulacao === "taxa") {
            body = { ...body, multiplicador: formatarValor(multiplicador), }
        }

        return this.request.post(`${SIMULADOR_SUBORDINACAO}`, body)
    }

    saveSimulation(idCarteira, nome_carteira, data_referencia, caixa, percentil, plCota, subordinacoes) {
        const body = {
            uuid: idCarteira,
            nome_carteira: nome_carteira,
            data_referencia: data_referencia,
            caixa: removerPontosVirgulaValor(caixa),
            percentil: percentil,
            plCotaTotal: removerPontosVirgulaValor(plCota),
            dados_carteira_entrada_simulacao: subordinacoes
        }
        return this.request.post(`${SIMULADOR_SUBORDINACAO}/registro`, body)
    }

}

export default SimuladorService
