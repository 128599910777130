import { Button, Checkbox, FormControlLabel, Grid, Paper } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import FabButton from 'src/components/fab-button'
import ModalDialogPage from 'src/components/modal-dialog'
import { DATE_FORMAT } from 'src/utils/constants'
import { localeBRDataGridText } from 'src/utils/data-grid-local-text'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import CetipService from 'src/services/cetip-service'
import PropTypes from 'prop-types'
import EditableInputComponent from 'src/components/editable-input-component'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import ClientService from 'src/services/client-service'
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded'
import { formatDateString } from 'src/utils/format-date'
import ConfirmDialog from 'src/components/confirm-dialog'
import MultiSelectAutoComplete from 'src/components/mult-select-autocomplete'
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded'

const RegisterProduct = (props) => {
    const initialCETIPList = [{
        id: 1,
        CETIP: '',
        Clientes: []
    }]
    const initialEditCetip = {
        CETIP: '',
        Clientes: [],
        UpdateClientes: []
    }

    const [idListDelete, setIdListDelete] = useState([])
    const [productList, setProductList] = useState([])
    const [productListTmp, setProductListTmp] = useState([])
    const [clientList, setClientList] = useState([])
    const [editCetip, setEditCetip] = useState(initialEditCetip)
    const [dueDate, setDueDate] = useState(false)
    const [openAddProduct, setOpenAddProduct] = useState(false)
    const [dialogDelete, setDialogDelete] = useState(false)
    const [openEditClientCetip, setOpenEditClientCetip] = useState(false)
    const cetipService = new CetipService()
    const clientService = new ClientService()
    const [newCetipList, setNewCetipList] = useState(initialCETIPList)
    // const [keyModalEditCetip, setKeyModalEditCetip] = useState(Math.random())

    useEffect(() => {
        loadCetips()
    }, [])

    const loadClientPrices = () => {
        props.loading(true)
        clientService.loadClientPrices().then((response) => {
            props.loading(false)
            const { body } = response
            const { data } = body
            const clientOptions = data.map((item) => ({ value: item.ID, label: item.Nome }))
            const clientes = [{ value: 10, label: 'C2P' }, ...clientOptions]

            setClientList(clientes)
        })
    }

    const loadCetips = () => {
        props.loading(true)
        cetipService.getCetips()
            .then(response => {
                const { body } = response
                const { data } = body
                const list = data.map((obj, index) => ({ ...obj, id: index }))
                setProductList(list)
                setProductListTmp(list)
                loadClientPrices()
            })
            .finally(() => {
                props.loading(false)
            })
    }

    const onChangeDueDate = (event) => {
        const checked = event.target.checked

        if (checked) {
            const filteredDueDate = productList.filter(product => dayjs(product.vencimento).format(DATE_FORMAT) <= dayjs().format(DATE_FORMAT))
            setProductList(filteredDueDate)
        } else {
            setProductList(productListTmp)
        }
        setDueDate(checked)
    }

    const actionButton = {
        icon: <AddRoundedIcon />,
        onClick: () => {
            setNewCetipList(initialCETIPList)
            setOpenAddProduct(true)
        },
        color: "primary",
    }

    const addClienteCetip = async (updateClient) => {
        const filter = updateClient.UpdateClientes.filter(
            obj1 => !editCetip.UpdateClientes.some(obj2 => obj1.value === obj2.value)
        )[0]
        props.loading(true)
        await cetipService.registerNewCetips({ CETIP: editCetip.CETIP, IDCliente: filter.value }).finally(() => props.loading(false))
        openModalEditCetips(editCetip.CETIP)
    }

    const removeClietCetip = async (updateClient) => {
        const filter = editCetip.UpdateClientes.filter(
            obj1 => !updateClient.UpdateClientes.some(obj2 => obj1.value === obj2.value)
        )[0]
        props.loading(true)
        await cetipService.deleteCetip(filter.ID).finally(() => props.loading(false))
        openModalEditCetips(editCetip.CETIP)
    }

    const handleEditClient = (value) => {
        const distinctSelectedValues = value.filter(
            (obj, index, self) =>
                index === self.findIndex(o => o.value === obj.value && o.label === obj.label))
        console.log("distinctSelectedValues", distinctSelectedValues)

        const updateClient = { ...editCetip, UpdateClientes: distinctSelectedValues }

        if (updateClient.UpdateClientes.length > editCetip.UpdateClientes.length) {
            addClienteCetip(updateClient)
        }

        if (editCetip.UpdateClientes.length > updateClient.UpdateClientes.length) {
            removeClietCetip(updateClient)
        }
    }


    const deleteRow = async () => {
        props.loading(true)
        for (const idItem of idListDelete) {
            await await cetipService.deleteCetip(idItem)
        }
        props.loading(false)
        loadCetips()
    }

    const openDeleteCetip = (params) => {
        props.loading(true)
        cetipService.getClientsFromCetip(params.row.CETIP)
            .then((response) => {
                props.loading(false)
                const { body } = response
                const { results } = body
                setDialogDelete(true)
                const IDList = results.map((item) => item.ID)
                setIdListDelete(IDList)
            }).catch(() => {
                props.loading(false)
            })
    }

    const openModalEditCetips = (cetip) => {
        props.loading(true)
        cetipService.getClientsFromCetip(cetip)
            .then((response) => {
                props.loading(false)
                const { body } = response
                const { results } = body
                setOpenEditClientCetip(true)

                let list = []
                for (const result of results) {
                    console.log(result)
                    list.push({
                        value: result.IDCliente,
                        label: result.NomeCliente,
                        ID: result.ID
                    })
                }

                console.log(list)
                console.log(results)

                setEditCetip({
                    CETIP: cetip,
                    Clientes: results,
                    UpdateClientes: list
                })
                // setKeyModalEditCetip(Math.random())
            }).finally(() => {
                props.loading(false)
            })
    }

    const columns = [
        {
            field: 'id',
            headerName: '#',
            maxWidth: 100
        },
        {
            field: 'CETIP',
            headerName: 'CETIP',
            flex: true,
            minWidth: 250,
        },
        {
            field: 'TipoAtivo',
            headerName: 'Tipo Ativo',
            flex: true,
            minWidth: 100,
            maxWidth: 150,
        },
        {
            field: 'Rating',
            headerName: 'Rating',
            flex: 1,
            type: 'date',
            valueFormatter: (params) => formatDateString(params.value),
            minWidth: 150,
        },
        {
            field: 'FINCS',
            headerName: 'FINCS',
            flex: 1,
            type: 'date',
            valueFormatter: (params) => formatDateString(params.value),
            minWidth: 150,
        },
        {
            field: 'SpreadsIndicativos',
            headerName: 'Spread Indicativos',
            flex: 1,
            type: 'date',
            valueFormatter: (params) => formatDateString(params.value),
            minWidth: 150,
        },
        {
            field: 'PreçosProcessados',
            headerName: 'Precos Processados',
            flex: 1,
            type: 'date',
            valueFormatter: (params) => formatDateString(params.value),
            minWidth: 180,
        },
        {
            field: 'Clientes',
            headerName: 'Clientes',
            flex: true,
            minWidth: 150,
            renderCell: (params) => {
                return <Button onClick={() => {
                    setEditCetip(initialEditCetip)
                    openModalEditCetips(params.row.CETIP)
                }}><OpenInNewRoundedIcon /></Button>
            }
        },
        {
            field: 'Remover',
            headerName: 'Remover',
            flex: true,
            minWidth: 150,
            renderCell: (params) => {
                return <Button color="error" onClick={() => openDeleteCetip(params)}><DeleteOutlineOutlinedIcon /></Button>
            }
        },
    ]

    const handleCETIPChange = (field, id, value) => {
        const existCetip = productList.filter(item => item.CETIP === value)
        if (existCetip.length > 0) {
            props.alert(`CETIP: ${value} já cadastrado`, "warning")
        }

        setNewCetipList(prevState => prevState.map(product => {
            return product.id === id ? { ...product, [field]: value } : product
        }))
    }

    const handleClientChange = (id, value) => {
        console.log(value)
        const index = newCetipList.findIndex(product => product.id === id)
        if (index === -1) return // Se não encontrar o item, retorna

        const updatedProduct = { ...newCetipList[index], Clientes: value }
        const updatedList = [...newCetipList]
        updatedList[index] = updatedProduct

        setNewCetipList(updatedList)
    }

    const addNewCetipRegister = () => {
        setNewCetipList((prevState) => {
            const lastId = prevState[prevState.length - 1].id
            const nextId = lastId + 1
            return [
                ...prevState,
                {
                    id: nextId,
                    CETIP: '',
                    Clientes: []
                }
            ]
        })
    }

    const handleRemoveCETIP = (id) => {
        console.log("handleRemoveCETIP", id)

        if (newCetipList.length < 2) return
        const list = newCetipList.filter(item => item.id !== id)
        setNewCetipList(list)
    }

    const registerProducts = async () => {
        setOpenAddProduct(false)
        newCetipList.forEach((item) => {
            item.Clientes.forEach(async (client) => {
                const body = {
                    'CETIP': item.CETIP,
                    'IDCliente': client.value
                }
                props.loading(true)
                await cetipService.registerNewCetips(body)
            })
        })
        loadCetips()
    }

    // const updateClientCetip = async () => {
    //     console.debug(editCetip)
    //     setOpenEditClientCetip(false)

    //     const toCreate = editCetip.UpdateClientes.filter(item => !editCetip.Clientes.some(cliente => cliente.IDCliente === item))
    //     const toDelete = editCetip.Clientes.filter(cliente => !editCetip.UpdateClientes.includes(cliente.IDCliente)).map(cliente => cliente.ID)
    //     console.log("To Delete:", toDelete)
    //     props.loading(true)


    //     for (let itemDelete of toDelete) {
    //         await cetipService.deleteCetip(itemDelete)
    //     }
    //     for (let create of toCreate) {
    //         console.log("create:", create)
    //         await cetipService.registerNewCetips({ CETIP: editCetip.CETIP, IDCliente: create.value })
    //     }


    //     props.loading(false)

    // }

    return <>
        <Grid container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
        >
            <Grid item>
                <Grid item>
                    <Button variant="outlined" onClick={() => loadCetips()}><RefreshRoundedIcon /></Button>
                </Grid>
            </Grid>
            <Grid item>
                <FormControlLabel
                    label="Vencidos"
                    control={
                        <Checkbox
                            checked={dueDate}
                            onChange={onChangeDueDate}
                        />
                    }
                />
            </Grid>
        </Grid>

        <Grid container>
            <Grid item xs={12} md={12} sm={12} xl={12}>
                <Paper>
                    <DataGrid
                        autoHeight
                        rows={productList}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 10,
                                },
                            },
                        }}
                        pageSizeOptions={[10, 25, 50, 100]}
                        disableRowSelectionOnClick
                        sortingOrder={['asc', 'desc']}
                        localeText={localeBRDataGridText}
                    />
                </Paper>
            </Grid>
        </Grid>

        <br /><br /><br />
        <ModalDialogPage
            open={openAddProduct}
            title="Cadastro de Novos CETIP's"
            close={() => {
                setOpenAddProduct(false)
            }}
            button={<FabButton action={actionButton} />}>

            <Grid container direction={'column'} rowSpacing={2}>
                <Grid item width={'100%'}>
                    <DataGrid
                        autoHeight={false}
                        autoPageSize={false}
                        showColumnVerticalBorder
                        showCellVerticalBorder
                        hideFooter
                        rows={newCetipList}
                        columns={[
                            {
                                field: 'id',
                                headerName: '#',
                                align: "center",
                                disableColumnMenu: true,
                                sortable: false,
                                headerAlign: "center",
                            },
                            {
                                field: 'CETIP',
                                headerName: 'CETIP',
                                align: "center",
                                disableColumnMenu: true,
                                sortable: false,
                                maxWidth: 200,
                                minWidth: 200,
                                headerAlign: "center",
                                renderCell: ({ id, value }) => <EditableInputComponent
                                    editable={true}
                                    fieldValue={value}
                                    handleChange={(cellValue) => handleCETIPChange('CETIP', id, cellValue)}
                                />,
                            },
                            {
                                field: 'Clientes',
                                headerName: 'Clientes',
                                align: "center",
                                disableColumnMenu: true,
                                sortable: false,
                                flex: 1,
                                headerAlign: "center",
                                renderCell: ({ id }) => {
                                    const values = newCetipList.filter(c => c.id === id)[0].Clientes
                                    return <MultiSelectAutoComplete
                                        id={`multiple-${id}}`}
                                        options={clientList}
                                        limitTags={4}
                                        selectedValues={values}
                                        handleChange={(value) => {
                                            handleClientChange(id, value)
                                        }}
                                    />
                                }
                            },
                            {
                                field: 'Remover',
                                headerName: 'Remover',
                                align: "center",
                                disableColumnMenu: true,
                                sortable: false,
                                headerAlign: "center",
                                renderCell: (params) => (
                                    <Button onClick={() => handleRemoveCETIP(params.row.id)} color="error">
                                        <DeleteOutlineOutlinedIcon />
                                    </Button>
                                ),
                            }
                        ]}
                        disableRowSelectionOnClick
                    />
                </Grid>
                <br />
                <Grid container justifyContent={"space-between"}>

                    <Grid item>
                        <Grid container spacing={1} justifyContent={"flex-start"}>
                            <Grid item>
                                <Button variant="contained" onClick={addNewCetipRegister}><AddRoundedIcon /> CETIP</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={1} justifyContent={"flex-end"}>
                            <Grid item>
                                <Button variant="outlined" onClick={() => { setOpenAddProduct(false) }}>Cancelar</Button>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" onClick={() => { registerProducts() }}>Cadastrar</Button>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>

        </ModalDialogPage>

        <ModalDialogPage
            // key={keyModalEditCetip}
            open={openEditClientCetip}
            title={`Atualizar Clientes de Preços | CETIP: ${editCetip.CETIP}`}
            close={() => {
                setOpenEditClientCetip(false)
            }}>

            <Grid container direction={'column'} rowSpacing={2}>
                <Grid item sm={12} md={6} xl={8}>
                    <MultiSelectAutoComplete
                        label={"Lista de Clientes"}
                        options={clientList}
                        limitTags={10}
                        helperText={editCetip.UpdateClientes.length == 0 ? "Necessário informar no mínimo um cliente" : null}
                        selectedValues={editCetip.UpdateClientes}
                        handleChange={(value) => handleEditClient(value)}
                    />
                </Grid>
                <br />
                <Grid container justifyContent={"flex-end"}>
                    <Grid item>
                        <Grid container spacing={1} justifyContent={"flex-end"}>
                            <Grid item>
                                <Button variant="outlined" onClick={() => { setOpenEditClientCetip(false) }}>Fechar</Button>
                            </Grid>
                            {/* <Grid item>
                                <Button variant="contained" onClick={() => { updateClientCetip() }}>Atualizar</Button>
                            </Grid> */}
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>

        </ModalDialogPage>

        <ConfirmDialog
            open={dialogDelete}
            title={"Deseja remover esse registro?"}
            onConfirm={() => deleteRow()}
            onClose={() => setDialogDelete(false)}>
            <span>Essa ação não pode ser desfeita.</span>
        </ConfirmDialog>
    </>

}

RegisterProduct.propTypes = {
    loading: PropTypes.func,
    alert: PropTypes.func
}

export default RegisterProduct