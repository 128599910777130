import React, { useEffect, useState } from "react"
import { formatDateDataGrid } from "src/utils/format-date"
import PropTypes from 'prop-types'
import ParameterPaginationTable from "src/components/table/parameter-pagination-table"
import ReceivableService from "src/services/receivable-service"


const BacenInadimplenciaParameters = (props) => {
    const receivableService = new ReceivableService()
    const [data, setData] = useState([])
    const [pagination, setPagination] = useState({ count: 0, next: null, previous: null })

    useEffect(() => {
        loadData()
    }, [])

    const loadData = () => {
        props.loading(true)
        receivableService.loadPaginationEndpoint(props.endpoint).then((response) => {
            props.loading(false)

            const { body } = response
            const { results, count, next, previous } = body
            const list = results.map((obj, index) => ({ ...obj, id: index }))
            setPagination({ count: count, next: next, previous: previous })
            setData(list)
        })
    }

    const createRow = async () => {
        const body = {
            "UF": null,
            "TipoIF": null,
            "Subsetor": null,
            "Segmento": null,
            "Ocupacao": null,
            "CNAE_secao": null,
            "CNAE_subclasse": null,
            "Porte": null,
            "ModalidadeBacen": null,
            "Origem": null,
            "Indexador": null,
            "QtdOperacoes": null,
            "Avencer90d": null,
            "Avencer91a360d": null,
            "Avencer361a1080d": null,
            "Avencer1081a1800d": null,
            "Avencer1801a5400d": null,
            "Avencermais5401d": null,
            "Over15d": null,
            "CarteiraAtiva": null,
            "CarteiraInadimplidaArrastada": null,
            "AtivosProblematicos": null,
        }


        props.loading(true)
        return receivableService.createTableEndpoint(props.endpoint, body).then(() => {
            props.loading(false)
            loadData(0, 25)
        }).catch((err) => {
            props.loading(false)
            throw err
        })
    }

    const updateRow = async (newRow) => {
        const {     ID, UF, TipoIF, Subsetor, Segmento, Ocupacao, CNAE_secao, 
            CNAE_subclasse, Porte, ModalidadeBacen, Origem, Indexador,
            QtdOperacoes, Avencer90d, Avencer91a360d, Avencer361a1080d, 
            Avencer1081a1800d, Avencer1801a5400d, Avencermais5401d, Over15d,
            CarteiraAtiva, CarteiraInadimplidaArrastada, AtivosProblematicos
        } = newRow

        const body = {
            "UF": UF,
            "TipoIF": TipoIF,
            "Subsetor": Subsetor,
            "Segmento": Segmento,
            "Ocupacao": Ocupacao,
            "CNAE_secao": CNAE_secao,
            "CNAE_subclasse": CNAE_subclasse,
            "Porte": Porte,
            "ModalidadeBacen": ModalidadeBacen,
            "Origem": Origem,
            "Indexador": Indexador,
            "QtdOperacoes": QtdOperacoes,
            "Avencer90d": Avencer90d,
            "Avencer91a360d": Avencer91a360d,
            "Avencer361a1080d": Avencer361a1080d,
            "Avencer1081a1800d": Avencer1081a1800d,
            "Avencer1801a5400d": Avencer1801a5400d,
            "Avencermais5401d": Avencermais5401d,
            "Over15d": Over15d,
            "CarteiraAtiva": CarteiraAtiva,
            "CarteiraInadimplidaArrastada": CarteiraInadimplidaArrastada,
            "AtivosProblematicos": AtivosProblematicos,
        }

        props.loading(true)
        return receivableService.updateTableEndpoint(props.endpoint, ID, body).then(() => {
            props.loading(false)
        }).catch((err) => {
            props.loading(false)
            throw err
        })
    }

    const deleteRow = async (row) => {
        console.log('Deleting row:', row)
        props.loading(true)
        const { ID } = row
        return receivableService.deleteTableEndpoint(props.endpoint, ID).then(() => {
            props.loading(false)
            const listRemoved = data.filter((item) => item.ID !== ID)
            setData(listRemoved)
        }).catch((err) => {
            props.loading(false)
            throw err
        })
    }

    const onFilter = async (filterList, limit, offset) => {
        const query = filterList.map(obj => `${encodeURIComponent(obj.field)}=${encodeURIComponent(obj.value)}`).join('&')

        props.loading(true)
        receivableService.loadPaginationEndpoint(props.endpoint, query, limit, offset).then((response) => {
            props.loading(false)
            const { body } = response
            const { results, count, next, previous } = body
            const list = results.map((obj) => ({ ...obj, id: obj.ID }))
            setPagination({ count: count, next: next, previous: previous })
            setData(list)
        })
    }

    const changePage = async (endpoint) => {
        props.loading(true)
        receivableService.paginate(endpoint).then((response) => {
            props.loading(false)
            const { body } = response
            const { results, count, previous, next } = body
            const list = results.map((obj) => ({ ...obj, id: obj.ID }))

            setPagination({ count: count, next: next, previous: previous })
            setData([])
            setData(list)
        })
    }

    const columns = [
        {
            field: 'ID',
            headerName: '#',
            disableColumnMenu: true,
            disableFilter: true,
            flex: true,
            minWidth: 80,
        },
        {
            field: 'UF',
            headerName: 'UF',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'TipoIF',
            headerName: 'TipoIF',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'Subsetor',
            headerName: 'Subsetor',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'Segmento',
            headerName: 'Segmento',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'Ocupacao',
            headerName: 'Ocupacao',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'CNAE_secao',
            headerName: 'CNAE_secao',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'CNAE_subclasse',
            headerName: 'CNAE_subclasse',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'Porte',
            headerName: 'Porte',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'ModalidadeBacen',
            headerName: 'ModalidadeBacen',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'Origem',
            headerName: 'Origem',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'Indexador',
            headerName: 'Indexador',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'QtdOperacoes',
            headerName: 'QtdOperacoes',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'Avencer90d',
            headerName: 'Avencer90d',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'Avencer91a360d',
            headerName: 'Avencer91a360d',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'Avencer361a1080d',
            headerName: 'Avencer361a1080d',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'Avencer1081a1800d',
            headerName: 'Avencer1081a1800d',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'Avencer1801a5400d',
            headerName: 'Avencer1801a5400d',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'Avencermais5401d',
            headerName: 'Avencermais5401d',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'Over15d',
            headerName: 'Over15d',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'CarteiraAtiva',
            headerName: 'CarteiraAtiva',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'CarteiraInadimplidaArrastada',
            headerName: 'CarteiraInadimplidaArrastada',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'AtivosProblematicos',
            headerName: 'AtivosProblematicos',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },        
        {
            field: 'CriadoEm',
            headerName: 'Data de Criação',
            flex: true,
            minWidth: 150,
            disableColumnMenu: true,
            disableFilter: true,
            renderCell: (params) => formatDateDataGrid(params)
        },
        {
            field: 'AtualizadoEm',
            headerName: 'Data de Atualização',
            flex: true,
            minWidth: 150,
            disableColumnMenu: true,
            disableFilter: true,
            renderCell: (params) => formatDateDataGrid(params)
        }
    ]

    return (
        <div>
            <ParameterPaginationTable
                columns={columns}
                data={data}
                setData={setData}
                createRow={createRow}
                deleteRow={deleteRow}
                updateRow={updateRow}
                filterFunction={onFilter}
                pagination={pagination}
                onChangePage={changePage}
                customFilter={true} />
        </div>
    )
}


BacenInadimplenciaParameters.propTypes = {
    loading: PropTypes.func,
    endpoint: PropTypes.string,
}

export default BacenInadimplenciaParameters
