import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { DATALAKE_EMISSAO_CARACTERISTICAS_PATH } from 'src/utils/api-routes'
import ParameterDatalakeEmissaoCaracteristicasTable from 'src/pages/parameters/parameters-datalake/tables/parameter-datalake-emissao-caracteristicas'

const TableEmissaoCaracteristicas = ({ loading, cetipIsin }) => {
    const [filters, setFilters] = useState([])
    const [tableKey, setTableKey] = useState(Math.random())

    useEffect(() => {
        loadFilter()
    }, [cetipIsin])

    const loadFilter = () => {
        const { cetip } = cetipIsin
        let newFilters = []

        if (cetip && cetip !== undefined) {
            newFilters.push({ field: 'CETIP', value: cetip })
        }

        console.log(filters)
        setFilters(newFilters)
        setTableKey(Math.random())
    }

    return <ParameterDatalakeEmissaoCaracteristicasTable
        key={tableKey}
        customFilter={false}
        filters={filters}
        endpoint={DATALAKE_EMISSAO_CARACTERISTICAS_PATH}
        loading={loading} />
}

TableEmissaoCaracteristicas.propTypes = {
    loading: PropTypes.func,
    cetipIsin: PropTypes.object
}

export default TableEmissaoCaracteristicas