import React, { useEffect, useState } from "react"
import { formatDateDataGrid } from "src/utils/format-date"
import PropTypes from 'prop-types'
import dayjs from "dayjs"
import { DATE_FORMAT } from "src/utils/constants"
import ParameterPaginationTable from "src/components/table/parameter-pagination-table"
import ReceivableService from "src/services/receivable-service"

const BacenTaxasJurosParameters = (props) => {
    const receivableService = new ReceivableService()
    const [data, setData] = useState([])
    const [pagination, setPagination] = useState({ count: 0, next: null, previous: null })

    useEffect(() => {
        loadData()
    }, [])

    const loadData = () => {
        props.loading(true)
        receivableService.loadPaginationEndpoint(props.endpoint).then((response) => {
            props.loading(false)

            const { body } = response
            const { results, count, next, previous } = body
            const list = results.map((obj, index) => ({ ...obj, id: index }))
            setPagination({ count: count, next: next, previous: previous })
            setData(list)
        })
    }

    const createRow = async () => {
        const body = {
            "DataReferencia": dayjs().format(DATE_FORMAT),
            "DataInicial": dayjs().format(DATE_FORMAT),
            "DataFinal": dayjs().format(DATE_FORMAT),
            "CodSegmento": null,
            "CodModalidade": null,
            "IF": null,
            "TaxaMes": null,
            "TaxaAno": null,
        }


        props.loading(true)
        return receivableService.createTableEndpoint(props.endpoint, body).then(() => {
            props.loading(false)
            loadData(0, 25)
        }).catch((err) => {
            props.loading(false)
            throw err
        })
    }

    const updateRow = async (newRow) => {
        const {     ID, DataReferencia, DataInicial, DataFinal, CodSegmento, CodModalidade, IF, TaxaMes, TaxaAno
        } = newRow

        const body = {
            "DataReferencia": DataReferencia,
            "DataInicial": DataInicial,
            "DataFinal": DataFinal,
            "CodSegmento": CodSegmento,
            "CodModalidade": CodModalidade,
            "IF": IF,
            "TaxaMes": TaxaMes,
            "TaxaAno": TaxaAno,
        }

        props.loading(true)
        return receivableService.updateTableEndpoint(props.endpoint, ID, body).then(() => {
            props.loading(false)
        }).catch((err) => {
            props.loading(false)
            throw err
        })
    }

    const deleteRow = async (row) => {
        console.log('Deleting row:', row)
        props.loading(true)
        const { ID } = row
        return receivableService.deleteTableEndpoint(props.endpoint, ID).then(() => {
            props.loading(false)
            const listRemoved = data.filter((item) => item.ID !== ID)
            setData(listRemoved)
        }).catch((err) => {
            props.loading(false)
            throw err
        })
    }

    const onFilter = async (filterList, limit, offset) => {
        const query = filterList.map(obj => `${encodeURIComponent(obj.field)}=${encodeURIComponent(obj.value)}`).join('&')

        props.loading(true)
        receivableService.loadPaginationEndpoint(props.endpoint, query, limit, offset).then((response) => {
            props.loading(false)
            const { body } = response
            const { results, count, next, previous } = body
            const list = results.map((obj) => ({ ...obj, id: obj.ID }))
            setPagination({ count: count, next: next, previous: previous })
            setData(list)
        })
    }

    const changePage = async (endpoint) => {
        props.loading(true)
        receivableService.paginate(endpoint).then((response) => {
            props.loading(false)
            const { body } = response
            const { results, count, previous, next } = body
            const list = results.map((obj) => ({ ...obj, id: obj.ID }))

            setPagination({ count: count, next: next, previous: previous })
            setData([])
            setData(list)
        })
    }

    const columns = [
        {
            field: 'ID',
            headerName: '#',
            disableColumnMenu: true,
            disableFilter: true,
            flex: true,
            minWidth: 80,
        },
        {
            field: 'DataReferencia',
            headerName: 'DataReferencia',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'date',
            disableColumnMenu: true,
            editable: true,
        },
        {
            field: 'DataInicial',
            headerName: 'DataInicial',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'date',
            disableColumnMenu: true,
            editable: true,
        },
        {
            field: 'DataFinal',
            headerName: 'DataFinal',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'date',
            disableColumnMenu: true,
            editable: true,
        },
        {
            field: 'CodSegmento',
            headerName: 'CodSegmento',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'CodModalidade',
            headerName: 'CodModalidade',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'IF',
            headerName: 'IF',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'TaxaMes',
            headerName: 'TaxaMes',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'TaxaAno',
            headerName: 'TaxaAno',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'CriadoEm',
            headerName: 'Data de Criação',
            flex: true,
            minWidth: 150,
            disableColumnMenu: true,
            disableFilter: true,
            renderCell: (params) => formatDateDataGrid(params)
        },
        {
            field: 'AtualizadoEm',
            headerName: 'Data de Atualização',
            flex: true,
            minWidth: 150,
            disableColumnMenu: true,
            disableFilter: true,
            renderCell: (params) => formatDateDataGrid(params)
        }
    ]

    return (
        <div>
            <ParameterPaginationTable
                columns={columns}
                data={data}
                setData={setData}
                createRow={createRow}
                deleteRow={deleteRow}
                updateRow={updateRow}
                filterFunction={onFilter}
                pagination={pagination}
                onChangePage={changePage}
                customFilter={true} />
        </div>
    )
}


BacenTaxasJurosParameters.propTypes = {
    loading: PropTypes.func,
    endpoint: PropTypes.string,
}

export default BacenTaxasJurosParameters
