import React from "react"
import { Box, Typography } from "@mui/material"
import dayjs from "dayjs"
import { DATE_FORMAT_SHOW } from "src/utils/constants"

// Função para renderizar a célula com uma data formatada
export const renderCellDate = (params) => {
    if (params.value) {
        return dayjs(params.value).format(DATE_FORMAT_SHOW)
    }
    return "" // Retorna string vazia caso o valor seja nulo/indefinido
}

// Função para renderizar uma coluna com texto multilinha
export const renderTextColumn = (params) => (
    <Box sx={{ whiteSpace: "pre-line", height: "auto" }}>
        <Typography component="span">{params.value}</Typography>
    </Box>
)

// Função para calcular a largura mínima com base no conteúdo
export const calculateMinWidth = (rows, field) => {
    const longestText = rows.reduce((longest, row) => {
        const text = row[field] ? row[field].toString() :  "" // Garante que o texto seja uma string
        return text.length > longest.length ? text : longest
    }, "")
    return Math.max(longestText.length * 6, 150) // Largura mínima de 150
}

// funcao para substituir valores de um payload
// exemplo de entrada: [{field: meu_campo, value: valor}]
export const substituirValoresPayload = (objeto, mapeamento) => {
    const novoObjeto = { ...objeto }

    mapeamento.forEach((item) => {
        const { field, value } = item
        const valorOrigem = objeto[field]
        novoObjeto[field] = value !== undefined ? value : valorOrigem
    })

    return novoObjeto
}