import { Button, Grid, TextField } from "@mui/material"
import React, { useEffect, useState } from "react"
import ModalDialogPage from "src/components/modal-dialog"
import TableGray from "src/components/table/table-gray"
import EmissaoService from "src/services/emissao-service"
import PropTypes from 'prop-types'
import { DATE_FORMAT, DATE_FORMAT_SHOW } from "src/utils/constants"
import dayjs from "dayjs"
import SkeletonLine from "src/components/skeleton-line"
import { formatDecimalCaseReplaceComma, formatDecimalCaseWithComma } from "src/utils/format-decimal"
import { ordenaPorDataReferencia } from "src/utils/date-format"
import ParameterTable from "src/components/table/parameter-table"


const tableDefault = [
    {
        cells: ["CETIP: ", "ISIN: "]
    }
]

const UltimoNegocio = ({ value, loading, cetipIsin }) => {
    const emissaoService = new EmissaoService()
    const ultimoNegocioDefault = { data_referencia: "", pupar: "", spread_credito: "" }
    const [openMostrarTodos, setOpenMostrarTodos] = useState(false)
    const [ultimoNegocioState, setUltimoNegocioState] = useState(ultimoNegocioDefault)
    const [loadingUltimoNegocio, setLoadingUltimoNegocio] = useState(false)
    const [rowsUltimoNegocio, setRowsUltimoNegocio] = useState([])
    const [table, setTable] = useState(tableDefault)

    useEffect(() => {
        if (validateValue()) loadData(value)
    }, [value])

    const validateValue = () => {
        if (value && value.length > 4) {
            return true
        }

        return false
    }

    const loadData = (cetipIsin) => {
        console.log('UltimoEventoCreditoEmissao: ', cetipIsin)
        setLoadingUltimoNegocio(true)
        emissaoService.getUltimoSpreadNegociados(cetipIsin).then((response) => {
            const { body } = response
            const { data } = body

            if (data !== null) {
                const { DataReferencia, PercentualPUPAR, SpreadCreditoFinal } = data

                setUltimoNegocioState({
                    data_referencia: dayjs(DataReferencia).format(DATE_FORMAT_SHOW),
                    pupar: formatDecimalCaseReplaceComma(PercentualPUPAR),
                    spread_credito: formatDecimalCaseWithComma(SpreadCreditoFinal, 4),
                })
            } else {
                setUltimoNegocioState(ultimoNegocioDefault)
            }
        }).catch(() => {
            setUltimoNegocioState(ultimoNegocioDefault)
        }).finally(() => {
            setLoadingUltimoNegocio(false)
        })
    }

    const buildTable = () => {
        setTable([{ cells: [`CETIP: ${cetipIsin.cetip}`, `ISIN: ${cetipIsin.isin}`] }])
    }

    const openUltimoNegocioModal = () => {
        if (!validateValue()) {
            setOpenMostrarTodos(true)
            return
        }

        loading(true)
        buildTable()
        const dataMeno60Dias = dayjs().subtract(60, 'day')
        const minus60Days = dataMeno60Dias.format(DATE_FORMAT)
        console.log(minus60Days)
        // emissaoService.ultimoNegocios("get", `?CETIP=${value.toUpperCase()}&DataReferencia__gte=${minus60Days}`)
        emissaoService.ultimoNegocios("get", `?CETIP=${value.toUpperCase()}`)
            .then((response) => {
                setOpenMostrarTodos(true)
                const { body } = response
                const { results } = body

                const rows = ordenaPorDataReferencia(results, "desc").map((obj, index) => ({ ...obj, id: index }))
                setRowsUltimoNegocio(rows)
            })
            .catch(() => { })
            .finally(() => {
                loading(false)
            })
    }

    return <>
        {loadingUltimoNegocio ? <SkeletonLine /> :
            <Grid container direction="row" alignItems={"center"} justifyContent="space-between" spacing={2}>
                <Grid item xs={12} md={3} sm={12} xl={3}>
                    <TextField fullWidth label="Data Referência" value={ultimoNegocioState.data_referencia} />
                </Grid>
                <Grid item xs={12} md={3} sm={12} xl={3}>
                    <TextField fullWidth label="%PUPAR" value={ultimoNegocioState.pupar} />
                </Grid>
                <Grid item xs={12} md={3} sm={12} xl={3}>
                    <TextField fullWidth label="Spread Crédito" value={ultimoNegocioState.spread_credito} />
                </Grid>
                <Grid item xs={12} md={3} sm={12} xl={3}>
                    <Button fullWidth variant="contained" onClick={openUltimoNegocioModal}>Mostrar Todos</Button>
                </Grid>
            </Grid>
        }

        <ModalDialogPage open={openMostrarTodos}
            title="Últimos 60 dias de negócios"
            close={() => {
                setOpenMostrarTodos(false)
            }}>
            <>
                <Grid container direction="column" spacing={2}>
                    <Grid item xs={12} md={12} xl={12}>
                        <TableGray data={table} />
                    </Grid>
                </Grid>
                <br />
                <Grid container direction="column">
                    <Grid item xs={12} md={12} xl={12}>

                        <ParameterTable
                            columns={[
                                { field: 'DataReferencia', headerName: 'Data Referencia', flex: 1 },
                                { field: 'PU_MTM', headerName: 'PU MTM', flex: 1 },
                                { field: 'PercentualPUPAR', headerName: '%PUPAR', flex: 1 },
                                { field: 'TaxaMTM', headerName: 'Taxa MTM', flex: 1 },
                                { field: 'SpreadCreditoFinal', headerName: 'Spread Crédito', flex: 1 },
                                { field: 'VolumeFinanceiro', headerName: 'Volume FInanceiro', flex: 1 },
                            ]}
                            data={rowsUltimoNegocio}
                            setData={setRowsUltimoNegocio}
                            createRow={() => {}}
                            deleteRow={() => {}}
                            updateRow={() => {}}
                            loadData={loadData}
                            disableDeleteColumn={true}
                            disableAddRow={true}
                            buttonAddVariant={""}
                            buttonAddTitle={""}
                        />
                    </Grid>
                </Grid>
            </>
        </ModalDialogPage>
    </>
}

UltimoNegocio.propTypes = {
    value: PropTypes.string,
    cetipIsin: PropTypes.object,
    loading: PropTypes.func,

}

export default UltimoNegocio