import React from 'react'
import PropTypes from 'prop-types'
import CircleIcon from '@mui/icons-material/Circle'
import AutorenewIcon from '@mui/icons-material/Autorenew'
import { Chip } from '@mui/material'
import { styled } from "@mui/material/styles"

const RotatingIcon = styled(AutorenewIcon)(() => ({
    animation: "spin 2s linear infinite",
    "@keyframes spin": {
        "0%": {
            transform: "rotate(0deg)",
        },
        "100%": {
            transform: "rotate(360deg)",
        },
    },
}))

const OnlineStatus = ({ label, loading, status }) => {

    return (
        <>
            {loading ?
                <Chip icon={<RotatingIcon />} variant="outlined" label={"Carregando"} /> :
                <Chip icon={<CircleIcon />} variant="outlined" color={status ? "success" : "error"} label={label} />
            }
        </>
    )
}

OnlineStatus.propTypes = {
    loading: PropTypes.bool,
    label: PropTypes.string,
    status: PropTypes.bool
}


export default OnlineStatus