import { Box, Grid, Tab, Tabs } from "@mui/material"
import React, { useState } from "react"
import LayoutPage from "src/components/page-layout/layout-page"
import AlertMessage from "src/components/alert-message"
import RaterAnaliseSetorial from "./subpages/RaterAnaliseSetorial"
import RaterClassificacao from "./subpages/RaterClassificacao"

const SetorialRaterPage = () => {
    const tabs = [
        { id: 0, description: "Análise Setorial" },
        { id: 1, description: "Classificação" },
    ]

    const [selectedTab, setSelectedTab] = useState(0)
    const [loading, setLoading] = useState(false)
    const [alertMessageState, setAlertMessageState] = React.useState({
        open: false,
        message: "",
        type: ""
    })

    const openAlert = (message, type) => {
        setAlertMessageState({
            ...alertMessageState,
            open: true,
            message: message,
            type: type
        })
    }

    const closeAlert = () => {
        setAlertMessageState({ ...alertMessageState, open: false })
    }

    const handleChange = (_event, newValue) => {
        setSelectedTab(newValue)
    }

    const renderContent = () => {
        switch (selectedTab) {
            case 0:
                return (
                    <>
                        <RaterAnaliseSetorial  loading={setLoading} alert={openAlert} />
                    </>
                )
            case 1:
                return (
                    <>
                        <RaterClassificacao loading={setLoading} alert={openAlert} />
                    </>
                )
            default:
                return null
        }
    }

    return (
        <LayoutPage title="Setorial" loading={loading}>
            <AlertMessage open={alertMessageState.open} message={alertMessageState.message} type={alertMessageState.type} close={closeAlert} />
            <Box
                sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    height: "100vh",
                    display: "flex",
                    flexDirection: "column"
                }}>
                
                <Tabs value={selectedTab} onChange={handleChange} aria-label="tabs">
                    {tabs.map((tab) => (
                        <Tab label={tab.description} key={tab.id} />
                    ))}
                </Tabs>

                <Box
                    sx={{
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                    }}>
                    <Grid
                        container
                        direction="row"
                        alignContent="flex-start"
                        justifyContent="flex-start"
                        spacing={0}
                        sx={{
                            padding: 2,
                            height: "100%", // altura de 100% da Box
                        }}>
                        <Grid item xs={12} sm={12} md={12} xl={12} sx={{ height: "100%" }}>
                        {renderContent()}
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </LayoutPage>
    )
}

export default SetorialRaterPage