import {Grid} from "@mui/material"
import React from "react"

const RaterClassificacao = () => {

    return (
        <Grid>
        
        </Grid>

    )
}

export default RaterClassificacao