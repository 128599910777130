import React, { useEffect, useState } from "react"
import { formatDateDataGrid } from "src/utils/format-date"
import PropTypes from 'prop-types'
import dayjs from "dayjs"
import { DATE_FORMAT } from "src/utils/constants"
import DatalakeService from "src/services/datalake-service"
import ParameterPaginationTable from "src/components/table/parameter-pagination-table"

const ParameterRaterMMResultadoEmpresaTable = (props) => {
    const dataLakeService = new DatalakeService()
    const [data, setData] = useState([])
    const [pagination, setPagination] = useState({ count: 0, next: null, previous: null })

    useEffect(() => {
        loadData()
    }, [])

    const loadData = () => {
        props.loading(true)
        dataLakeService.loadPaginationEndpoint(props.endpoint).then((response) => {
            props.loading(false)

            const { body } = response
            const { results, count, next, previous } = body
            const list = results.map((obj, index) => ({ ...obj, id: index }))
            setPagination({ count: count, next: next, previous: previous })
            setData(list)
        })
    }

    const createRow = async () => {
        const body = {
            "Ticker": null,
            "PD": null,
            "MesBasePD": null,
            "Fonte": null,
            "EWMA": null,
            "nrmeses": null,
            "CPFCNPJ": null,
            "RaizCNPJ": null,
            "DataReferencia": dayjs().format(DATE_FORMAT),
            "MesBaseRating": null,
            "FonteRating": null,
            "ScoreRatingFinal": null,
            "RatingFinal": null,
            "Validado": "False",
        }
        console.log("DataReferencia:", body.DataReferencia)

        props.loading(true)
        return dataLakeService.createTableEndpoint(props.endpoint, body).then(() => {
            props.loading(false)
            loadData(0, 25)
        }).catch((err) => {
            props.loading(false)
            throw err
        })
    }

    const updateRow = async (newRow) => {
        const {ID, Ticker, PD, MesBasePD, Fonte, EWMA, nrmeses, CPFCNPJ, RaizCNPJ, DataReferencia, MesBaseRating, FonteRating, ScoreRatingFinal, RatingFinal, Validado
        } = newRow

        const body = {
            "Ticker": Ticker,
            "PD": PD,
            "MesBasePD": MesBasePD,
            "Fonte": Fonte,
            "EWMA": EWMA,
            "nrmeses": nrmeses,
            "CPFCNPJ": CPFCNPJ,
            "RaizCNPJ": RaizCNPJ,
            "DataReferencia": DataReferencia,
            "MesBaseRating": MesBaseRating,
            "FonteRating": FonteRating,
            "ScoreRatingFinal": ScoreRatingFinal,
            "RatingFinal": RatingFinal,
            "Validado": Validado
        }

        props.loading(true)
        return dataLakeService.updateTableEndpoint(props.endpoint, ID, body).then(() => {
            props.loading(false)
        }).catch((err) => {
            props.loading(false)
            throw err
        })
    }

    const deleteRow = async (row) => {
        props.loading(true)
        const { ID } = row
        return dataLakeService.deleteTableEndpoint(props.endpoint, ID).then(() => {
            props.loading(false)
            const listRemoved = data.filter((item) => item.ID !== ID)
            setData(listRemoved)
        }).catch((err) => {
            props.loading(false)
            throw err
        })
    }

    const onFilter = async (filterList, limit, offset) => {
        const query = filterList.map(obj => `${encodeURIComponent(obj.field)}=${encodeURIComponent(obj.value)}`).join('&')

        props.loading(true)
        dataLakeService.loadPaginationEndpoint(props.endpoint, query, limit, offset).then((response) => {
            props.loading(false)
            const { body } = response
            const { results, count, next, previous } = body
            const list = results.map((obj) => ({ ...obj, id: obj.ID }))
            setPagination({ count: count, next: next, previous: previous })
            setData(list)
        })
    }

    const changePage = async (endpoint) => {
        props.loading(true)
        dataLakeService.paginate(endpoint).then((response) => {
            props.loading(false)
            const { body } = response
            const { results, count, previous, next } = body
            const list = results.map((obj) => ({ ...obj, id: obj.ID }))

            setPagination({ count: count, next: next, previous: previous })
            setData([])
            setData(list)
        })
    }

    const columns = [
        {
            field: 'ID',
            headerName: '#',
            disableColumnMenu: true,
            disableFilter: true,
            flex: true,
            minWidth: 80,
        },
        {
            field: 'Ticker',
            headerName: 'Ticker',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'PD',
            headerName: 'PD',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'MesBasePD',
            headerName: 'MesBasePD',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'Fonte',
            headerName: 'Fonte',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'EWMA',
            headerName: 'EWMA',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'nrmeses',
            headerName: 'nrmeses',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'CPFCNPJ',
            headerName: 'CPFCNPJ',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'RaizCNPJ',
            headerName: 'RaizCNPJ',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'DataReferencia',
            headerName: 'DataReferencia',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'date',
            disableColumnMenu: true,
            editable: true,
        },
        {
            field: 'MesBaseRating',
            headerName: 'MesBaseRating',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'FonteRating',
            headerName: 'FonteRating',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'ScoreRatingFinal',
            headerName: 'ScoreRatingFinal',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'RatingFinal',
            headerName: 'RatingFinal',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'Validado',
            headerName: 'Validado',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'CriadoEm',
            headerName: 'Data de Criação',
            flex: true,
            minWidth: 150,
            disableColumnMenu: true,
            disableFilter: true,
            renderCell: (params) => formatDateDataGrid(params)
        },
        {
            field: 'AtualizadoEm',
            headerName: 'Data de Atualização',
            flex: true,
            minWidth: 150,
            disableColumnMenu: true,
            disableFilter: true,
            renderCell: (params) => formatDateDataGrid(params)
        }
    ]

    return (
        <div>
            <ParameterPaginationTable
                columns={columns}
                data={data}
                setData={setData}
                createRow={createRow}
                deleteRow={deleteRow}
                updateRow={updateRow}
                filterFunction={onFilter}
                pagination={pagination}
                onChangePage={changePage}
                customFilter={true} />
        </div>
    )
}

ParameterRaterMMResultadoEmpresaTable.propTypes = {
    loading: PropTypes.func,
    endpoint: PropTypes.string,
}

export default ParameterRaterMMResultadoEmpresaTable