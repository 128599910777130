import React, { useEffect, useState } from "react"
import { formatDateDataGrid } from "src/utils/format-date"
import PropTypes from 'prop-types'
import DatalakeService from "src/services/datalake-service"
import dayjs from "dayjs"
import { DATE_FORMAT } from "src/utils/constants"
import ParameterPaginationTable from "src/components/table/parameter-pagination-table"
import { calculateMinWidth, renderTextColumn } from "src/utils/table-render-functions"

const ParameterDatalakeFincsMarketDataTable = (props) => {
    const dataLakeService = new DatalakeService()
    const [data, setData] = useState([])
    const [pagination, setPagination] = useState({ count: 0, next: null, previous: null })

    useEffect(() => {
        if(props.filters && props.filters.length > 0) {
            onFilter(props.filters, 25, 0)
            return
        }

        loadData()
    }, [])

    const loadData = () => {
        props.loading(true)
        dataLakeService.loadPaginationEndpoint(props.endpoint).then((response) => {
            props.loading(false)

            const { body } = response
            const { results, count, next, previous } = body
            setPagination({ count: count, next: next, previous: previous })
            setData(results)
        })
    }

    const createRow = async () => {
        const body = {
            "cetip": "N/A",
            "cod_fincs": "N/A",
            "tipo_ativo": "N/A",
            "tipo_serie": "N/A",
            "lote_id": crypto.randomUUID(),
            "isin": "N/A",
            "classe": "N/A",
            "lei_12431": "N/A",
            "num_serie": 0,
            "num_emissao": 0,
            "data_primeira_integralizacao": dayjs().format(DATE_FORMAT),
            "emissao": dayjs().format(DATE_FORMAT),
            "vencimento": dayjs().format(DATE_FORMAT),
            "fluxo_base": 0,
            "data_resgate_antecipado": dayjs().format(DATE_FORMAT),
            "indicador": "N/A",
            "taxa_accrual": 0,
            "pu_emissao": 0,
            "qt_emitida": 0,
            "coordenador_lider": "N/A",
            "escriturador": "N/A",
            "banco_liquidante": "N/A",
            "agente_fiduciario": "N/A",
            "distribuicao": "N/A",
            "emissor_razao_social": "N/A",
            "emissor_nome_fantasia": "N/A",
            "emissor_cnpj": "N/A",
            "emissor_grupo_economico": "N/A",
            "devedor_razao_social": "N/A",
            "devedor_nome_fantasia": "N/A",
            "devedor_cnpj": "N/A",
            "devedor_grupo_economico": "N/A",
            "cedente_razao_social": "N/A",
            "cedente_nome_fantasia": "N/A",
            "cedente_cnpj": "N/A",
            "cedente_grupo_economico": "N/A",
            "tipo_cota": "N/A",
            "garantia": "N/A",
            "lastro": "N/A",
            "resgate_antecipado": 0,
            "status_homologacao": "N/A",
            "volume_financeiro": 0
        }

        props.loading(true)
        return dataLakeService.createTableEndpoint(props.endpoint, body).then(() => {
            props.loading(false)
            loadData(0, 25)
        }).catch((err) => {
            props.loading(false)
            throw err
        })
    }

    const updateRow = async (newRow) => {
        const { id, cetip, cod_fincs, tipo_ativo, tipo_serie, lote_id, isin, classe, lei_12431, num_serie, num_emissao, data_primeira_integralizacao,
            emissao, vencimento, fluxo_base, data_resgate_antecipado, indicador, taxa_accrual, pu_emissao, qt_emitida, coordenador_lider, escriturador,
            banco_liquidante, agente_fiduciario, distribuicao, emissor_razao_social, emissor_nome_fantasia, emissor_cnpj,
            emissor_grupo_economico, devedor_razao_social, devedor_nome_fantasia, devedor_cnpj, devedor_grupo_economico, cedente_razao_social,
            cedente_nome_fantasia, cedente_cnpj, cedente_grupo_economico, tipo_cota, garantia, lastro, resgate_antecipado,
            status_homologacao, volume_financeiro
        } = newRow

        const body = {
            "cetip": cetip,
            "cod_fincs": cod_fincs,
            "tipo_ativo": tipo_ativo,
            "tipo_serie": tipo_serie,
            "lote_id": lote_id,
            "isin": isin,
            "classe": classe,
            "lei_12431": lei_12431,
            "num_serie": num_serie,
            "num_emissao": num_emissao,
            "data_primeira_integralizacao": data_primeira_integralizacao,
            "emissao": emissao,
            "vencimento": vencimento,
            "fluxo_base": fluxo_base,
            "data_resgate_antecipado": data_resgate_antecipado,
            "indicador": indicador,
            "taxa_accrual": taxa_accrual,
            "pu_emissao": pu_emissao,
            "qt_emitida": qt_emitida,
            "coordenador_lider": coordenador_lider,
            "escriturador": escriturador,
            "banco_liquidante": banco_liquidante,
            "agente_fiduciario": agente_fiduciario,
            "distribuicao": distribuicao,
            "emissor_razao_social": emissor_razao_social,
            "emissor_nome_fantasia": emissor_nome_fantasia,
            "emissor_cnpj": emissor_cnpj,
            "emissor_grupo_economico": emissor_grupo_economico,
            "devedor_razao_social": devedor_razao_social,
            "devedor_nome_fantasia": devedor_nome_fantasia,
            "devedor_cnpj": devedor_cnpj,
            "devedor_grupo_economico": devedor_grupo_economico,
            "cedente_razao_social": cedente_razao_social,
            "cedente_nome_fantasia": cedente_nome_fantasia,
            "cedente_cnpj": cedente_cnpj,
            "cedente_grupo_economico": cedente_grupo_economico,
            "tipo_cota": tipo_cota,
            "garantia": garantia,
            "lastro": lastro,
            "resgate_antecipado": resgate_antecipado,
            "status_homologacao": status_homologacao,
            "volume_financeiro": volume_financeiro,
        }

        props.loading(true)
        return dataLakeService.updateTableEndpoint(props.endpoint, id, body).then(() => {
            props.loading(false)
        }).catch((err) => {
            props.loading(false)
            throw err
        })
    }

    const deleteRow = async (row) => {
        props.loading(true)
        const { id } = row
        return dataLakeService.deleteTableEndpoint(props.endpoint, id).then(() => {
            props.loading(false)
            const listRemoved = data.filter((item) => item.id !== id)
            setData(listRemoved)
        }).catch((err) => {
            props.loading(false)
            throw err
        })
    }

    const onFilter = async (filterList, limit, offset) => {
        const query = filterList.map(obj => `${encodeURIComponent(obj.field)}=${encodeURIComponent(obj.value)}`).join('&')

        props.loading(true)
        dataLakeService.loadPaginationEndpoint(props.endpoint, query, limit, offset).then((response) => {
            props.loading(false)
            const { body } = response
            const { results, count, next, previous } = body
            const list = results.map((obj) => ({ ...obj, id: obj.id }))
            setPagination({ count: count, next: next, previous: previous })
            setData(list)
        })
    }

    const changePage = async (endpoint) => {
        props.loading(true)
        dataLakeService.paginate(endpoint).then((response) => {
            props.loading(false)
            const { body } = response
            const { results, count, previous, next } = body

            setPagination({ count: count, next: next, previous: previous })
            setData([])
            setData(results)
        })
    }

    const agenteFiduciario = calculateMinWidth(data, "agente_fiduciario")
    const emissorRazaoSocial = calculateMinWidth(data, "emissor_razao_social")
    const emissorNomeFantasia = calculateMinWidth(data, "emissor_nome_fantasia")

    const columns = [
        {
            field: 'id',
            headerName: '#',
            disableColumnMenu: true,
            disableFilter: true,
            flex: true,
            minWidth: 80,
        },
        {
            field: 'cetip',
            headerName: 'cetip',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'cod_fincs',
            headerName: 'cod_fincs',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'tipo_ativo',
            headerName: 'tipo_ativo',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'tipo_serie',
            headerName: 'tipo_serie',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'lote_id',
            headerName: 'lote_id',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'isin',
            headerName: 'isin',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'classe',
            headerName: 'classe',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'lei_12431',
            headerName: 'lei_12431',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'num_serie',
            headerName: 'num_serie',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'num_emissao',
            headerName: 'num_emissao',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'data_primeira_integralizacao',
            headerName: 'data_primeira_integralizacao',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'date',
            // type: 'date',
            // valueGetter: ({ value }) => value && dayjs(value).toDate(),
            // valueFormatter: params => dayjs(params.value).format(DATE_FORMAT),
            // valueParser: (newValue) => dayjs(newValue).format(DATE_FORMAT),
            disableColumnMenu: true,
            editable: true,
        },
        {
            field: 'emissao',
            headerName: 'emissao',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'date',
            // type: 'date',
            // valueGetter: ({ value }) => value && dayjs(value).toDate(),
            // valueFormatter: params => dayjs(params.value).format(DATE_FORMAT),
            // valueParser: (newValue) => dayjs(newValue).format(DATE_FORMAT),
            disableColumnMenu: true,
            editable: true,
        },
        {
            field: 'vencimento',
            headerName: 'vencimento',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'date',
            // type: 'date',
            // valueGetter: ({ value }) => value && dayjs(value).toDate(),
            // valueFormatter: params => dayjs(params.value).format(DATE_FORMAT),
            // valueParser: (newValue) => dayjs(newValue).format(DATE_FORMAT),
            disableColumnMenu: true,
            editable: true,
        },
        {
            field: 'fluxo_base',
            headerName: 'fluxo_base',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'data_resgate_antecipado',
            headerName: 'data_resgate_antecipado',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'indicador',
            headerName: 'indicador',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'taxa_accrual',
            headerName: 'taxa_accrual',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'pu_emissao',
            headerName: 'pu_emissao',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'qt_emitida',
            headerName: 'qt_emitida',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'coordenador_lider',
            headerName: 'coordenador_lider',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'escriturador',
            headerName: 'escriturador',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'banco_liquidante',
            headerName: 'banco_liquidante',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'agente_fiduciario',
            headerName: 'agente_fiduciario',
            flex: 1,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true,
            minWidth: agenteFiduciario, 
            renderCell: renderTextColumn
        },
        {
            field: 'distribuicao',
            headerName: 'distribuicao',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'emissor_razao_social',
            headerName: 'emissor_razao_social',
            flex: 1,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true,
            minWidth: emissorRazaoSocial,
            renderCell: renderTextColumn
        },
        {
            field: 'emissor_nome_fantasia',
            headerName: 'emissor_nome_fantasia',
            flex: 1,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true,
            minWidth: emissorNomeFantasia,
            renderCell: renderTextColumn
        },
        {
            field: 'emissor_cnpj',
            headerName: 'emissor_cnpj',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'emissor_grupo_economico',
            headerName: 'emissor_grupo_economico',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'devedor_razao_social',
            headerName: 'devedor_razao_social',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'devedor_nome_fantasia',
            headerName: 'devedor_nome_fantasia',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'devedor_cnpj',
            headerName: 'devedor_cnpj',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'devedor_grupo_economico',
            headerName: 'devedor_grupo_economico',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'cedente_razao_social',
            headerName: 'cedente_razao_social',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'cedente_nome_fantasia',
            headerName: 'cedente_nome_fantasia',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'cedente_cnpj',
            headerName: 'cedente_cnpj',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'cedente_grupo_economico',
            headerName: 'cedente_grupo_economico',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'tipo_cota',
            headerName: 'tipo_cota',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'garantia',
            headerName: 'garantia',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'lastro',
            headerName: 'lastro',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'resgate_antecipado',
            headerName: 'resgate_antecipado',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'status_homologacao',
            headerName: 'status_homologacao',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'volume_financeiro',
            headerName: 'volume_financeiro',
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
            typeComponent: 'number',
            type: 'number',
            disableColumnMenu: true,
            editable: true
        },
        {
            field: 'criado_em',
            headerName: 'Data de Criação',
            flex: true,
            minWidth: 150,
            disableColumnMenu: true,
            disableFilter: true,
            renderCell: (params) => formatDateDataGrid(params)
        },
        {
            field: 'atualizado_em',
            headerName: 'Data de Atualização',
            flex: true,
            minWidth: 150,
            disableColumnMenu: true,
            disableFilter: true,
            renderCell: (params) => formatDateDataGrid(params)
        }
    ]

    return (
        <div>
            <ParameterPaginationTable
                columns={columns}
                data={data}
                disableToolbar={props.disableToolbar ? props.disableToolbar : false}
                setData={setData}
                createRow={createRow}
                deleteRow={deleteRow}
                updateRow={updateRow}
                filterFunction={onFilter}
                pagination={pagination}
                onChangePage={changePage}
                customFilter={true} />
        </div>
    )
}

ParameterDatalakeFincsMarketDataTable.propTypes = {
    filters: PropTypes.array,
    loading: PropTypes.func,
    endpoint: PropTypes.string,
    disableToolbar: PropTypes.bool,
}

export default ParameterDatalakeFincsMarketDataTable
