import { Button, Grid, TextField } from "@mui/material"
import React, { useEffect, useState } from "react"
import ModalDialogPage from "src/components/modal-dialog"
import TableGray from "src/components/table/table-gray"
import EmissaoService from "src/services/emissao-service"
import PropTypes from 'prop-types'
import dayjs from "dayjs"
import { DATE_FORMAT, DATE_FORMAT_SHOW } from "src/utils/constants"
import SkeletonLine from "src/components/skeleton-line"
import { ordenaPorDataReferencia } from "src/utils/date-format"
import ParameterTable from "src/components/table/parameter-table"
import { calculateMinWidth, renderCellDate, renderTextColumn } from "src/utils/table-render-functions"

const defaultTable = [
    {
        cells: ["CETIP: ", "ISIN: ", "Vencimento: ", "Data Resgate Antecipado: "]
    },
    {
        cells: ["CPFCNPJ: ", "Devedor: ", "Setor: ", "Subsetor:"]
    }
]

const UltimoRatingEmissao = ({ admin, cetipIsin, value, loading, dadosEmpresa }) => {
    const ultimoRatingEmissaoDefault = { data_validade: "", fonte: "", rating: "" }
    const [openMostrarTodos, setOpenMostrarTodos] = useState(false)
    const emissaoService = new EmissaoService()
    const [ultimoRatingEmissao, setUltimoRatingEmissao] = useState(ultimoRatingEmissaoDefault)
    const [loadingRatingEmissao, setLoadingRatingEmissao] = useState(false)
    const [rowsEmissao, setRowsEmissao] = useState([])
    const [table, setTable] = useState(defaultTable)

    useEffect(() => {
        console.log('UltimoRatingEmissao: ', value)
        if (validateValue()) {
            loadData(value)
        }
    }, [value])

    const validateValue = () => {
        if (value && value.length > 4) {
            return true
        }
        return false
    }

    const buildTable = (setor, subsetor) => {
        setTable([
            {
                cells: [`CETIP: ${cetipIsin.cetip}`, `ISIN: ${cetipIsin.isin}`, `Vencimento: ${dadosEmpresa.vencimento ? dayjs(dadosEmpresa.vencimento).format(DATE_FORMAT_SHOW) : ""}`, `Data Resgate Antecipado: ${dadosEmpresa.dataResgateAntecipado}`]
            },
            {
                cells: [`CPFCNPJ: ${dadosEmpresa.cpfCnpj}`, `Devedor: ${dadosEmpresa.devedor}`, `Setor: ${setor}`, `Subsetor: ${subsetor}`]
            }
        ])
    }

    const loadData = (value) => {
        setLoadingRatingEmissao(true)
        emissaoService.getUltimoRatingEmissao(value).then((response) => {
            const { body } = response
            const { data } = body

            if (data !== null) {
                const { DataValidade, fonte, Rating } = data

                setUltimoRatingEmissao({
                    data_validade: dayjs(DataValidade).format(DATE_FORMAT_SHOW),
                    fonte: fonte,
                    rating: Rating,
                })
            } else {
                setUltimoRatingEmissao(ultimoRatingEmissaoDefault)
            }
        }).catch(() => {
            setUltimoRatingEmissao(ultimoRatingEmissaoDefault)
        }).finally(() => {
            setLoadingRatingEmissao(false)
        })
    }

    const openRatingEmissaoModal = () => {
        if (!validateValue()) {
            setOpenMostrarTodos(true)
            return
        }

        loading(true)
        emissaoService.ratingEmissao("get", `?CETIP=${value.toUpperCase()}`)
            .then((response) => {
                setOpenMostrarTodos(true)
                const { body } = response
                const { results } = body
                const rows = ordenaPorDataReferencia(results, "desc").map((obj, index) => ({ ...obj, id: index }))
                const { Setor, SubSetor } = rows[0]
                buildTable(Setor, SubSetor)
                setRowsEmissao(rows)
            })
            .catch(() => { })
            .finally(() => {
                loading(false)
            })
    }

    const deleteItem = async (row) => {
        const { ID } = row

        loading(true)
        emissaoService.ratingEmissao("delete", ID)
            .then(() => { openRatingEmissaoModal() })
            .finally(() => {
                loading(false)
            })
    }

    const addRow = async () => {
        let lastItem = rowsEmissao[0]

        if (rowsEmissao.length === 0) {
            lastItem = {
                "CETIP": value,
                "CPFCNPJ": dadosEmpresa.CPFCNPJ,
                "DataReferencia": dayjs().format(DATE_FORMAT),
                "Devedor": "N/A",
                "Setor": "N/A",
                "SubSetor": "N/A",
                "Fonte": "N/A",
                "Rating": "N/A",
            }
        }

        loading(true)
        emissaoService.ratingEmissao("post", lastItem)
            .then(() => {
                openRatingEmissaoModal()
            })
            .catch(() => { })
            .finally(() => {
                loading(false)
            })
    }

    const updateRow = async (newRow) => {
        const { ID } = newRow
        console.log(newRow)
        loading(true)
        emissaoService.ratingEmissao("patch", ID, newRow)
            .finally(() => {
                loading(false)
            })

        return newRow
    }

    const minWidthAnaliseCredito = calculateMinWidth(rowsEmissao, "AnaliseCreditoEmissao")
    const minWidthObservacao = calculateMinWidth(rowsEmissao, "Observacao")

    return <>
        {loadingRatingEmissao ? <SkeletonLine /> :
            <Grid container direction="row" alignItems={"center"} justifyContent="space-between" spacing={2}>
                <Grid item xs={12} md={3} sm={12} xl={3}>
                    <TextField fullWidth label="Data Validade" value={ultimoRatingEmissao.data_validade} />
                </Grid>
                <Grid item xs={12} md={3} sm={12} xl={3}>
                    <TextField fullWidth label="Fonte" value={ultimoRatingEmissao.fonte} />
                </Grid>
                <Grid item xs={12} md={3} sm={12} xl={3}>
                    <TextField fullWidth label="Rating" value={ultimoRatingEmissao.rating} />
                </Grid>
                <Grid item xs={12} md={3} sm={12} xl={3}>
                    <Button fullWidth variant="contained" onClick={openRatingEmissaoModal}>Mostrar Todos</Button>
                </Grid>
            </Grid>
        }
        <ModalDialogPage open={openMostrarTodos}
            title="Último Rating Emissão"
            close={() => {
                setOpenMostrarTodos(false)
            }}>
            <>
                {/* <Loading show={loadingModal} /> */}
                <Grid container direction="column" spacing={2}>
                    <Grid item xs={12} md={12} xl={12}>
                        <TableGray data={table} />
                    </Grid>
                </Grid>
                <br />
                <ParameterTable
                    columns={[
                        { field: 'DataReferencia', typeComponent: 'date', headerName: 'Data Referencia', minWidth: 130, editable: admin, renderCell: renderCellDate },
                        { field: 'DataValidade', typeComponent: 'date', headerName: 'Data Validade', minWidth: 130, editable: admin, renderCell: renderCellDate },
                        { field: 'Fonte', headerName: 'Fonte', minWidth: 100, editable: admin },
                        { field: 'ScoreRating', headerName: 'Score Rating', minWidth: 100, editable: admin },
                        { field: 'Rating', headerName: 'Rating', minWidth: 100, editable: admin },
                        { field: 'Sinal', headerName: 'Sinal', minWidth: 100, editable: admin },
                        { field: 'Perspectiva', headerName: 'Perspectiva', minWidth: 100, editable: admin },
                        { field: 'Observacao', headerName: 'Observação', minWidth: minWidthObservacao, editable: admin, renderCell: renderTextColumn },
                        { field: 'AnaliseCreditoEmissao', headerName: 'Análise Crédito Emissão', minWidth: minWidthAnaliseCredito, editable: admin, renderCell: renderTextColumn },
                    ]}
                    data={rowsEmissao}
                    setData={setRowsEmissao}
                    createRow={addRow}
                    deleteRow={deleteItem}
                    updateRow={updateRow}
                    loadData={loadData}
                    disableDeleteColumn={!admin}
                    disableAddRow={!admin}
                    buttonAddVariant={"contained"}
                    buttonAddTitle={"Adicionar Rating"}
                />
            </>
        </ModalDialogPage>
    </>
}

UltimoRatingEmissao.propTypes = {
    admin: PropTypes.bool,
    cetipIsin: PropTypes.object,
    value: PropTypes.string,
    loading: PropTypes.func,
    dadosEmpresa: PropTypes.object
}

export default UltimoRatingEmissao